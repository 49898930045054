import {AdditionalOutcomesStep} from "./outcomes-steps";

export enum CallReportVoiceInputSteps {
    // Start,
    CustomerLocation,
    MeetingType,
    ScopeReview,
    ScopeUpdate,
    
    Interviewers,
    InterviewersNames,
    
    // IntervieweesCount,
    IntervieweesStep,
    
    ReviewMeetingOverviewQuestion,
    ReviewMeetingOverview,
    
    OutcomesCount,
    OutcomeWhat,
    OutcomeWhy,
    OutcomeClarify,
    AdditionalOutcomes,
    
    Insights,
    Opportunities,
    NextSteps,
    Notes,
}