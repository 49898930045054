import {Routes} from '@angular/router';

import {authenticatedGuard} from "./guards";
import {notAuthenticatedGuard} from "./guards";
import {subscriptionExpiredGuard} from "./guards";

import {SplashComponent} from "./screens/splash/splash.component";
import {LoginComponent} from "./screens/login/login.component";
import {SignupComponent} from "./screens/signup/signup.component";
import {HomeComponent} from "./screens/home/home.component";
import {ReportBuilderComponent} from "./screens/report-builder/report-builder.component";

import * as report from './screens/report-builder/screens';
import * as callReport from './screens/call-report-builder/screens';
import * as reportPractice from './screens/report-builder-practice/screens';

import {ReportViewerComponent} from "./screens/report-viewer/report-viewer.component";
import {ProfileComponent} from "./screens/profile/profile.component";
import {ProfileEditComponent} from "./screens/profile-edit/profile-edit.component";
import {AiGuideComponent} from "./screens/ai-guide/ai-guide.component";
import {SubscriptionExpiredComponent} from "./screens/subscription-expired/subscription-expired.component";
import {ContactUsComponent} from "./screens/contact-us/contact-us.component";
import {CookiePolicyComponent} from "./screens/cookie-policy/cookie-policy.component";
import {ELearningComponent} from "./screens/e-learning/e-learning.component";
import {ConfirmEmailComponent} from "./screens/confirm-email/confirm-email.component";
import {ResourcesComponent} from "./screens/resources/resources.component";
import {TermsComponent} from "./screens/terms/terms.component";
import {PrivacyComponent} from "./screens/privacy/privacy.component";
import {FaqComponent} from "./screens/faq/faq.component";
import {TryAiGuideComponent} from "./screens/try-ai-guide/try-ai-guide.component";
import {hadFirstChatGuard} from "./guards/had-first-chat.guard";
import {Error404Component} from "./screens/error-404/error-404.component";
import {ForgotPasswordComponent} from "./screens/forgot-password/forgot-password.component";
import {ResetPasswordComponent} from "./screens/reset-password/reset-password.component";
import {checkAuthenticationGuard} from "./guards/check-authentication.guard";
import {ReportsComponent} from "./screens/reports/reports.component";
import {MobileMenuComponent} from "./screens/mobile-menu/mobile-menu.component";
import {ReportBuilderPracticeComponent} from "./screens/report-builder-practice/report-builder-practice.component";
import {SignupConfirmEmailComponent} from "./screens/signup-confirm-email/signup-confirm-email.component";
import {PricingScreenComponent} from "./screens/pricing-screen/pricing-screen.component";
import {TwelveWaySliderScreenComponent} from "./screens/twelve-way-slider-screen/twelve-way-slider-screen.component";
import {BriefReportDownloadComponent} from "./screens/brief-report-download/brief-report-download.component";
import {CallReportViewerComponent} from "./screens/call-report-viewer/call-report-viewer.component";
import {CallReportBuilderComponent} from "./screens/call-report-builder/call-report-builder.component";

export const routes: Routes = [
    {path: '', pathMatch: 'full', title: 'SalesPrep System to prepare for B2B sales calls', component: SplashComponent, canActivate: [checkAuthenticationGuard]},
    // {path: 'splash/:token/:coupon', pathMatch: 'full', title: 'SalesPrep', component: SplashComponent},
    // {path: 'splash/:token', pathMatch: 'full', title: 'SalesPrep', component: SplashComponent},
    {path: 'contact-us', pathMatch: 'full', title: 'Contact Us', component: ContactUsComponent, canActivate: [checkAuthenticationGuard]},
    {path: 'privacy', pathMatch: 'full', title: 'Privacy', component: PrivacyComponent, canActivate: [checkAuthenticationGuard]},
    {path: 'terms', pathMatch: 'full', title: 'Terms', component: TermsComponent, canActivate: [checkAuthenticationGuard]},
    {path: 'cookie-policy', pathMatch: 'full', title: 'Cookie Policy', component: CookiePolicyComponent, canActivate: [checkAuthenticationGuard]},
    {path: 'faq', pathMatch: 'full', title: 'FAQs', component: FaqComponent , canActivate: [checkAuthenticationGuard]},
    {path: 'pricing', pathMatch: 'full', title: 'Sales Prep Pricing', component: PricingScreenComponent , canActivate: [checkAuthenticationGuard]},
    {path: '12-ways/:step', pathMatch: 'full', title: '12 Ways to Use SalesPrep', component: TwelveWaySliderScreenComponent , canActivate: [checkAuthenticationGuard]},
    {path: '12-ways', pathMatch: 'full', title: '12 Ways to Use SalesPrep', component: TwelveWaySliderScreenComponent , canActivate: [checkAuthenticationGuard]},

    {path: 'brief/:type', pathMatch: 'full', title: 'Brief Report Download', component: BriefReportDownloadComponent, canActivate: [checkAuthenticationGuard] },
    
    {path: 'login', pathMatch: 'full', title: 'Login', component: LoginComponent, canActivate: [notAuthenticatedGuard]},
    {path: 'forgot-password', pathMatch: 'full', title: 'Forgot Password', component: ForgotPasswordComponent, canActivate: [notAuthenticatedGuard]},
    {path: 'auth/confirmation/:userId/:code', pathMatch: 'full', title: 'Confirm Email', component: ConfirmEmailComponent, canActivate: [notAuthenticatedGuard]},
    {path: 'auth/reset-password/:email/:code', pathMatch: 'full', title: 'Reset Password', component: ResetPasswordComponent, canActivate: [notAuthenticatedGuard]},
    // {path: 'signup/:token/:coupon', pathMatch: 'full', title: 'Sign Up', component: SignupComponent, canActivate: [notAuthenticatedGuard]},
    // {path: 'signup/:token', pathMatch: 'full', title: 'Sign Up', component: SignupComponent, canActivate: [notAuthenticatedGuard]},
    
    {path: 'signup/confirmation', pathMatch: 'full', title: 'Sign Up Email Confirmation', component: SignupConfirmEmailComponent, canActivate: [notAuthenticatedGuard]},
    {path: 'signup/:mode', pathMatch: 'full', title: 'Sign Up', component: SignupComponent, canActivate: [notAuthenticatedGuard]},
    {path: 'signup', pathMatch: 'full', title: 'Sign Up', component: SignupComponent, canActivate: [notAuthenticatedGuard]},
    {path: 'subscription-expired', pathMatch: 'full', title: 'Subscription Expired', component: SubscriptionExpiredComponent, canActivate: [subscriptionExpiredGuard]},

    {path: 'dashboard', pathMatch: 'full', title: 'Sales Prep - Dashboard', component: HomeComponent, canActivate: [authenticatedGuard]},
    
    
    
    {path: 'profile', pathMatch: 'full', title: 'Account', component: ProfileComponent, canActivate: [authenticatedGuard] }, 
    {path: 'profile/setup-offerings', pathMatch: 'full', title: 'Setup Offerings', component: ProfileComponent, data: { setupOfferings: true }, canActivate: [authenticatedGuard] }, 
    {path: 'profile/edit/:mode', title: 'Account Setup', component: ProfileEditComponent, canActivate: [authenticatedGuard]},
    {path: 'profile/edit', title: 'Account Setup', component: ProfileEditComponent, canActivate: [authenticatedGuard]},

    {path: 'offerings', redirectTo: 'profile'},
    // {path: 'offerings', title: 'My Offerings', component: MyOfferingsComponent, canActivate: [authenticatedGuard]},
    // {path: 'offerings/add', title: 'Add Offering', component: AddOfferingComponent, canActivate: [authenticatedGuard]},
    // {path: 'offerings/add/:type', title: 'Add Offering', component: AddOfferingComponent, canActivate: [authenticatedGuard]},
    // {path: 'offerings/add/:type/:category', title: 'Add Offering', component: AddOfferingComponent, canActivate: [authenticatedGuard]},
    // {path: 'offerings/:id', title: 'Edit Offering', component: AddOfferingComponent, canActivate: [authenticatedGuard]},
    
    {path: 'practice', title: 'Report Practice', component: ReportBuilderPracticeComponent,
        children: [
            {path: '', pathMatch: 'full',       title: 'SalesPrep practice exercise', component: reportPractice.ReportPracticeStartComponent},
            {path: 'topics',       title: 'SalesPrep practice exercise - Discussion Topics', component: reportPractice.SelectTopicsComponent},
        ]
    },
    {path: 'try-ai-guide/:offering/:job/:topic', pathMatch: 'full', title: 'AI Guide', component: TryAiGuideComponent },
    {path: 'try-ai-guide', pathMatch: 'full', title: 'AI Guide', component: TryAiGuideComponent },
    {path: 'ai-guide/intro', pathMatch: 'full', title: 'AI Guide', component: TryAiGuideComponent },
    {path: 'ai-guide/try', pathMatch: 'full', title: 'Try AI Guide', data: {try: true}, component: AiGuideComponent },

    {path: 'ai-guide/start', pathMatch: 'full', title: 'AI Guide', component: AiGuideComponent, canActivate: [authenticatedGuard ]},
    {path: 'ai-guide/:offeringId/:job/:topic', pathMatch: 'full', title: 'AI Guide', component: AiGuideComponent, canActivate: [authenticatedGuard]},
    {path: 'ai-guide/:offeringId/:job', pathMatch: 'full', title: 'AI Guide', component: AiGuideComponent, canActivate: [authenticatedGuard]},
    {path: 'ai-guide/:offeringId', pathMatch: 'full', title: 'AI Guide', component: AiGuideComponent, canActivate: [authenticatedGuard]},
    // {path: 'ai-guide/job', pathMatch: 'full', title: 'AI Guide', component: AiGuideComponent, canActivate: [authenticatedGuard]},    
    {path: 'ai-guide', pathMatch: 'full', title: 'AI Guide', component: AiGuideComponent, canActivate: [authenticatedGuard, hadFirstChatGuard]},
    
    {path: 'reports', pathMatch: 'full', title: 'Reports', component: ReportsComponent, canActivate: [authenticatedGuard]},
    {path: 'e-learning', pathMatch: 'full', title: 'AI Guide', component: ELearningComponent, canActivate: [authenticatedGuard]},
    {path: 'resources', pathMatch: 'full', title: 'Resources', component: ResourcesComponent, canActivate: [authenticatedGuard]},
    {path: 'mobile-menu', pathMatch: 'full', title: 'More', component: MobileMenuComponent, canActivate: [authenticatedGuard]},
    
    
    {path: 'call-report/:id', title: 'Report', component: CallReportViewerComponent, canActivate: [checkAuthenticationGuard]},
    {
        path: 'call-report-builder/:id', 
        title: 'Report', 
        component: CallReportBuilderComponent, 
        canActivate: [checkAuthenticationGuard],
        children: [
            {path: 'overview',  title: '1. Meeting Overview', component: callReport.MeetingOverviewScreenComponent},
            {path: 'outcomes/:index',  title: '2. Add Outcomes', component: callReport.OutcomesScreenComponent},
            {path: 'notes/:step',  title: '3. Other Things Learned', component: callReport.NotesScreenComponent},
            {path: '', redirectTo: 'overview', pathMatch: 'full'},
        ]
    },
    
    {path: 'report/:id', title: 'Report', component: ReportViewerComponent, canActivate: [checkAuthenticationGuard]},
    {
        path: 'report-builder',
        title: 'New report',
        component: ReportBuilderComponent,
        canActivate: [authenticatedGuard],
        children: [            
            {path: 'new/:query', title: '1. New report', component: report.SelectCompanyComponent},
            {path: 'new',       title: '1. New report', component: report.SelectCompanyComponent},
            {path: 'offering',  title: '2. Select Offering', component: report.SelectOfferingComponent},
            {path: 'job',       title: '3. Select Job-to-be-done', component: report.SelectJobToBeDoneComponent},
            // {path: 'generate',       title: 'Generating report...', component: report.GenerateReportComponent},
            {path: 'topics',    title: '4a. Select trends, problems, process steps', component: report.SelectDiscussionTopicsComponent},
            {path: 'news',    title: '4b. Select news articles', component: report.SelectDiscussionTopicsComponent},
            {path: 'questions',    title: '5. Create Exploratory Questions', component: report.ExploratoryQuestionsComponent},
            {path: 'date',    title: '6. Select Date and Time', component: report.SetMeetingDateComponent},
            {path: '', redirectTo: 'select-company', pathMatch: 'full'},
        ]
    },

    { path: '**', component: Error404Component }
];
