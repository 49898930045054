import {CallReportEditData, MeetingType} from "../../model";
import {CallReportVoiceInputSteps} from "./call-report-voice-input-steps";
import {CallReportVoiceInputStep, CallReportVoiceInputStepWithOptions} from "./model";

export class StartCallReportStep extends CallReportVoiceInputStepWithOptions {
    constructor() {
        super(
            CallReportVoiceInputSteps.Start,
            'Let’s start with a meeting overview.\nI’ll ask you for details I don’t already have.\nJust say “skip” or “next” if you don’t have an answer.\nTap next to begin…',
            []
        );
    }

    override complete(data: CallReportEditData, transcript: string): CallReportVoiceInputSteps {
        return this.step + 1;
    }

    override getValue = (data: CallReportEditData) => 'start';
}
export class CustomerLocationStep extends CallReportVoiceInputStep {
    constructor() {
        super(
            CallReportVoiceInputSteps.CustomerLocation,
            'First, what was the customer’s location?\nPlease say both city and state or country.'
        );
    }

    override complete(data: CallReportEditData, transcript: string): CallReportVoiceInputSteps {
        if (!transcript) return this.step;
        data.customerCompanyLocation = transcript;
        return this.step + 1;
    }
    
    override getValue = (data: CallReportEditData) => data.customerCompanyLocation;
}

export class MeetingTypeStep extends CallReportVoiceInputStepWithOptions {
    constructor() {
        super(
            CallReportVoiceInputSteps.MeetingType,
            'Was this meeting in-person or virtual?',
            [
                { title: '<em>In-person</em>', transcript: 'in person' },
                { title: '<em>Virtual</em>', transcript: 'virtual' },
            ]
        );
    }

    override complete(data: CallReportEditData, transcript: string): CallReportVoiceInputSteps {
        if (this.options[0].selected) {
            data.meetingType = MeetingType.InPerson;            
        } else if (this.options[1].selected) {
            data.meetingType = MeetingType.Virtual;            
        } else {
            return this.step;
        }
        return CallReportVoiceInputSteps.ScopeReview;
    }

    override getValue = (data: CallReportEditData) => {
        if (data.meetingType == MeetingType.InPerson) return this.options[0].transcript;
        if (data.meetingType == MeetingType.Virtual) return this.options[1].transcript;
        return '';
    };
}

export class ScopeReviewStep extends CallReportVoiceInputStepWithOptions {
    constructor() {
        super(
            CallReportVoiceInputSteps.ScopeReview,
            'Here’s what I have for the scope:\n“{SCOPE}”\nIs this still correct as is?',
            [
                { title: '<em>Yes</em>. It is correct as is.', transcript: 'yes' },
                { title: '<em>No</em>. I need to update it.', transcript: 'no' },
            ]
        );
    }

    override formatText(data: CallReportEditData): string {
        return this.text.replace('{SCOPE}', data.scope);
    }

    override complete(data: CallReportEditData, transcript: string): CallReportVoiceInputSteps {
        if (this.options[0].selected) {
            return CallReportVoiceInputSteps.Interviewers;
        } else if (this.options[1].selected) {
            return CallReportVoiceInputSteps.ScopeUpdate;
        }
        return this.step;
    }
}

export class ScopeUpdateStep extends CallReportVoiceInputStep {
    constructor() {
        super(
            CallReportVoiceInputSteps.ScopeUpdate,
            'Okay, no problem. What is the correct scope of your meeting?'
        );
    }
        
    override complete(data: CallReportEditData, transcript: string): CallReportVoiceInputSteps {
        if (!transcript) return this.step;
        data.scope = transcript;
        return CallReportVoiceInputSteps.Interviewers;
    }

    override getValue = (data: CallReportEditData) => data.scope;
}


export class ReviewMeetingOverviewQuestionStep extends CallReportVoiceInputStepWithOptions {
    constructor() {
        super(
            CallReportVoiceInputSteps.ReviewMeetingOverviewQuestion,
            'That’s it for the meeting overview. Would you like to review all your answers or move onto the next step?',
            [
                { title: '<em>Review</em>.', transcript: 'review' },
                { title: '<em>Move</em> onto step 2.', transcript: 'move' },
            ]
        );
    }

    override complete(data: CallReportEditData, transcript: string): CallReportVoiceInputSteps {
        if (this.options[0].selected) {
            return CallReportVoiceInputSteps.ReviewMeetingOverview;
        } else if (this.options[1].selected) {
            return CallReportVoiceInputSteps.OutcomesCount;
        }
        return this.step;
    }
}

export class ReviewMeetingOverviewStep extends CallReportVoiceInputStep {
    constructor() {
        super(
            CallReportVoiceInputSteps.ReviewMeetingOverview,
            'Here are all the answers I’ve collected for the meeting overview. Tap any of the answers to fix them. When you’re finished reviewing, just click next.'
        );
    }

    override complete(data: CallReportEditData, transcript: string): CallReportVoiceInputSteps {
        return CallReportVoiceInputSteps.OutcomesCount;
    }
}



export class InsightsStep extends CallReportVoiceInputStep {
    constructor() {
        super(
            CallReportVoiceInputSteps.Insights,
            'Any other insights?'
        );
    }

    override complete(data: CallReportEditData, transcript: string): CallReportVoiceInputSteps {
        data.insights = transcript;
        return this.step + 1;
    }

    override getValue = (data: CallReportEditData) => data.insights;
}

export class OpportunitiesStep extends CallReportVoiceInputStep {
    constructor() {
        super(
            CallReportVoiceInputSteps.Opportunities,
            'Any opportunities to pursue?'
        );
    }

    override complete(data: CallReportEditData, transcript: string): CallReportVoiceInputSteps {
        data.opportunities = transcript;
        return this.step + 1;
    }

    override getValue = (data: CallReportEditData) => data.opportunities;
}

export class NextStepsStep extends CallReportVoiceInputStep {
    constructor() {
        super(
            CallReportVoiceInputSteps.NextSteps,
            'What are the next steps?'
        );
    }

    override complete(data: CallReportEditData, transcript: string): CallReportVoiceInputSteps {
        data.nextSteps = transcript;
        return this.step + 1;
    }

    override getValue = (data: CallReportEditData) => data.nextSteps;
}

export class NotesStep extends CallReportVoiceInputStep {
    constructor() {
        super(
            CallReportVoiceInputSteps.Notes,
            'Anything else we should include?'
        );
    }

    override complete(data: CallReportEditData, transcript: string): CallReportVoiceInputSteps {
        data.notes = transcript;
        return this.step;
    }

    override getValue = (data: CallReportEditData) => data.notes;
}
