import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'formatText',
    standalone: true
})
export class FormatTextPipe implements PipeTransform {

    transform(text: string | undefined, ...args: unknown[]): unknown {
        if (!text) {
            return 'Not set';            
        }
        return text.replace(/\n/g, '\n\n')
    }

}
