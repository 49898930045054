import * as step from './steps';
import {CallReportVoiceInputStep} from "./model";
import * as interviewers from "./interviewers-steps";
import * as interviewees from "./interviewees-steps";
import * as outcomes from "./outcomes-steps";

export class CallReportVoiceInputStepsList {
    
    static steps: CallReportVoiceInputStep[] = [
        // { step: CallReportVoiceInputSteps.Start, text: 'Let’s start with a meeting overview.\nI’ll ask you for details I don’t already have.\nJust say “skip” or “next” if you don’t have an answer.\nTap next to begin…' },
        new step.CustomerLocationStep(),
        new step.MeetingTypeStep(),
        new step.ScopeReviewStep(),
        new step.ScopeUpdateStep(),
        
        new interviewers.InterviewersStep(),
        new interviewers.InterviewersNamesStep(),
        
        // new interviewees.IntervieweesCountStep(),
        new interviewees.IntervieweesStep(),
        
        new step.ReviewMeetingOverviewQuestionStep(),
        new step.ReviewMeetingOverviewStep(),
        
        new outcomes.OutcomesCountStep(),
        new outcomes.OutcomeWhatStep(),
        new outcomes.OutcomeWhyStep(),
        new outcomes.OutcomeClarifyStep(),
        new outcomes.AdditionalOutcomesStep(),
        
        new step.InsightsStep(),
        new step.OpportunitiesStep(),
        new step.NextStepsStep(),
        new step.NotesStep(),
    ];    
}
