import {Component, HostListener, OnInit} from '@angular/core';
import {RouterOutlet} from '@angular/router';
import {StateService} from "./services/state.service";
import {ProgressBarComponent} from "./components/progress-bar/progress-bar.component";
import {MessageService} from "./services/message.service";
import {ConfirmationDialogComponent} from "./components/confirmation-dialog/confirmation-dialog.component";
import {MobileNavComponent} from "./components/mobile-nav/mobile-nav.component";
import {AuthService} from "./services/auth.service";
import {UpdateManagerService} from "./services/update-manager.service";
import {UpdateAvailableComponent} from "./components/update-available/update-available.component";
import {MainHeaderComponent} from "./components/main-header/main-header.component";
import {TrialExpirationNoticeComponent} from "./components/trial-expiration-notice/trial-expiration-notice.component";
import {TrialExpirationNoticeService} from "./services/trial-expiration-notice.service";

@Component({
    selector: 'app-root',
    standalone: true,
    imports: [
        RouterOutlet,
        ProgressBarComponent,
        ConfirmationDialogComponent,
        MobileNavComponent,
        UpdateAvailableComponent,
        TrialExpirationNoticeComponent,
    ],
    templateUrl: './app.component.html',
    styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {
    
    title = 'SalesPrep';

    activeRequests = this.stateService.activeRequests;
    busy = this.stateService.busy;
    authenticated = this.authService.authenticated;
    
    constructor(protected stateService: StateService,
                public trialExpirationNoticeService: TrialExpirationNoticeService,
                public authService: AuthService,
                public updateManagerService: UpdateManagerService,
                public messageService: MessageService) {
    }

    ngOnInit(): void {
        this.updateManagerService.init();
        this.trialExpirationNoticeService.init();
    }

    @HostListener('document:keydown.escape', ['$event'])
    onEscapeKey(event: KeyboardEvent) {
        console.log('[AppComponent] onEscapeKey', event);
        this.messageService.cancelConfirmation();
    }
}
