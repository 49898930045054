import {ChangeDetectionStrategy, Component} from '@angular/core';

@Component({
    selector: 'app-ghostwriter-avatar',
    standalone: true,
    imports: [],
    templateUrl: './ghostwriter-avatar.component.html',
    styleUrl: './ghostwriter-avatar.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class GhostwriterAvatarComponent {

}
