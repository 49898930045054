<app-company-logo [company]="report.company"></app-company-logo>

<div class="details" qa="details">
    
    <div class="company" qa="company">{{report.company.name}}</div>
<!--    <div class="website" qa="website">{{report.company.website}}</div>-->
    <div class="date" [class.today]="today" qa="date">
        {{report.meetingDate | date}}
        <span class="time" qa="time">
            {{report.meetingTime | time }}
        </span>
        @if (today) {
            <app-badge class="today" qa="badge">TODAY</app-badge>
        }        
    </div>
    <div class="subject">
        <app-report-subject
                [offeringName]="report.offeringName"
                [offeringType]="report.offeringType"
                [job]="report.job"
        />
    </div>
    
    <div class="last-updated" qa="last-upd">
        
        @if (enableCallReport) {
            @if (report.callReport) {
                <a appPreventDefaults [routerLink]="['/call-report', report.id]" class="sp-link" qa="view-cr">View call report</a> |                            
            } @else {
                <a appPreventDefaults [routerLink]="['/call-report-builder', report.id]" class="sp-button yellow" qa="create-cr">
                    Create Call Report
                </a>
            }
        }
        
        Updated: <span class="number" qa="upd">{{report.lastUpdated | date:"M/d/YYYY"}}</span>
    </div>

</div>

<div class="menu" appClickOutside (clickOutside)="showMenu = false">
    <a (click)="displayMenu($event)">
        <i class="material-icons menu-icon" qa="more">more_vert</i>
    </a>
    @if (showMenu) {
        <div class="items">
            <a title="Click to remove report" (click)="remove($event)"><i class="material-icons menu-icon" qa="delete">delete</i></a>
        </div>
    }
</div>