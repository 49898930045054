import {IReportPart} from "../pdf-export/ireport-part";
import {BriefReportItem, BriefReportItemListItem} from "../../model";
import {CallReportPdfCheckboxSection} from "./call-report-pdf-checkbox-section";
import {CallReportPdfGenerator} from "./call-report-pdf-generator";


export class CallReportPdfCheckboxesSection implements IReportPart {
    
    private marginBottom = 10;
    
    constructor(private pdf: CallReportPdfGenerator, private title: string, private items: BriefReportItem[]) {
    }
        
    draw(y: number) {
        const pdf = this.pdf;
        const items = this.items;
        if (!items.length) {
            return 0;
        }

        y = pdf.subHeading(y, this.title);        

        items.forEach(a => {
            const s = new CallReportPdfCheckboxSection(pdf, {
                    title: a.title,
                    description: a.description,
                });
            
            if (y + s.getHeight() > pdf.pageHeight) {
                pdf.doc.addPage();
                y = pdf.styles.paddingY;
            }
            y = s.draw(y) + this.marginBottom;
        });

        return y;
    }
}