import {ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output} from '@angular/core';

@Component({
    selector: 'app-form-navigation-buttons',
    standalone: true,
    imports: [],
    templateUrl: './form-navigation-buttons.component.html',
    styleUrl: './form-navigation-buttons.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormNavigationButtonsComponent {
    
    @Input() nextEnabled = true;
    @Input() nextTitle = 'Next';
    
    @HostBinding('class.sp-form') f = true;
    
    @Output() onPrev = new EventEmitter();
    @Output() onNext = new EventEmitter();

}
