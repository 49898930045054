import {APP_INITIALIZER, ApplicationConfig, ErrorHandler, isDevMode} from '@angular/core';
import {provideRouter, withComponentInputBinding, withInMemoryScrolling} from '@angular/router';
import { Router } from '@angular/router';
import * as Sentry from "@sentry/angular";

import {routes} from './app.routes';
import {AuthService} from "./services/auth.service";
import {ProductsService} from "./services/products.service";
import { provideHttpClient, withFetch, withInterceptors } from "@angular/common/http";
import {authInterceptorInterceptor} from "./interceptors/auth-interceptor.interceptor";
import {provideAnimations} from "@angular/platform-browser/animations";
import {provideToastr} from "ngx-toastr";
import {provideServiceWorker} from '@angular/service-worker';
import { provideClientHydration } from '@angular/platform-browser';

export const appConfig: ApplicationConfig = {
    providers: [
        provideHttpClient(withFetch(), withInterceptors([authInterceptorInterceptor])),
        provideAnimations(),
        provideToastr(),
        provideRouter(routes, withComponentInputBinding(), withInMemoryScrolling({
            scrollPositionRestoration: 'top',
            anchorScrolling: 'enabled',
        })),
        AuthService,
        ProductsService,
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler(),
        },
        {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => {},
            deps: [Sentry.TraceService],
            multi: true,
        },
        provideServiceWorker('ngsw-worker.js', {
            enabled: !isDevMode(),
            registrationStrategy: 'registerWhenStable:30000'
        }), provideClientHydration()
    ]
};
