import { Component } from '@angular/core';

@Component({
  selector: 'app-speaking-animation',
  standalone: true,
  imports: [],
  templateUrl: './speaking-animation.component.html',
  styleUrl: './speaking-animation.component.scss'
})
export class SpeakingAnimationComponent {

}
