@for (message of _messages; track message.text; let i = $index) {
    <app-chat-message [attr.qa]="'role-' + (i + 1)"
        (onMessageSelected)="onMessageSelected.emit($event)"
        
        [novice]="novice"
        [intermediate]="intermediate"
        [expert]="expert"
        
        [userInitials]="userInitials"
        [message]="message"
        [sentences]="message.sentences"
        [speakingSentenceIndex]="message.speakingSentense"
    />
}

@if (loading || messages.length != _messages.length) {
    <div class="progress-activity">
        <span class="material-symbols-outlined sp-spin" qa="pr-chat">progress_activity</span>
    </div>    
} 
