import {Component, computed, OnInit} from '@angular/core';
import {} from "@angular/common/http";
import {ProductsService} from "../../services/products.service";
import {NewReportComponent} from "./components/new-report/new-report.component";
import {Router, RouterLink} from "@angular/router";
import {ReportService} from "../../services/report.service";
import {HomeReportTileComponent} from "./components/home-report-tile/home-report-tile.component";
import {AuthService} from "../../services/auth.service";
import {MainHeaderComponent} from "../../components/main-header/main-header.component";
import {MainPanelComponent} from "../../components/main-panel/main-panel.component";
import {SpinnerComponent} from "../../components/spinner/spinner.component";
import {UpdateAvailableComponent} from "../../components/update-available/update-available.component";
import {UpdateManagerService} from "../../services/update-manager.service";
import {StateService} from "../../services/state.service";
import {PureHomeComponent} from "./components/pure-home/pure-home.component";
import {SalesPrepReport} from "../../model";
import {MessageService} from "../../services/message.service";
import {FooterCompactComponent} from "../../components/footer-compact/footer-compact.component";
import {ElearningService} from "../../services/elearning.service";

@Component({
    selector: 'app-home',
    standalone: true,
    imports: [
        NewReportComponent,
        HomeReportTileComponent,
        RouterLink,
        MainHeaderComponent,
        MainPanelComponent,
        SpinnerComponent,
        UpdateAvailableComponent,
        PureHomeComponent,
        FooterCompactComponent,
    ],
    templateUrl: './home.component.html',
    styleUrl: './home.component.scss'
})
export class HomeComponent implements OnInit {

    reports = this.reportService.reports;
    reportsLoading = this.reportService.reportsLoading;
    modules = ElearningService.modules;
    eLearningData = this.eLearningService.eLearningData;
    
    constructor(private reportService: ReportService,
                private eLearningService: ElearningService,                
                private messageService: MessageService,
                public authService: AuthService, private router: Router) {
    }

    ngOnInit(): void {
        if (this.authService.authenticated()) {
            if (!this.authService.userProfile()?.company) {
                // let's ask user to specify company if it's empty
                // this one is needed for users who authenticated using their Google account  
                this.router.navigate(['/profile/edit/setup']);
            } else {
                this.eLearningService.reloadELearningData();
            }
        }        
    }

    buildReport(query: string) {
        this.router.navigate(['/report-builder', 'new', query]);
    }

    logout() {
        this.authService.logout();
    }

    removeReport(report: SalesPrepReport) {
        this.reportService.remove(report);
    }
}