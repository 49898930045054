import {DatePipe} from "@angular/common";
import {BriefReportBase, SalesPrepReport, UserProfile} from "../../model";
import {IReportPart} from "../pdf-export/ireport-part";
import {MainHeaderSection} from "./layout/main-header-section";
import {HeaderSection} from "./layout/header-section";
import {FooterSection} from "./layout/footer-section";
import {PdfGeneratorBase} from "../pdf-export";

export class CallReportPdfGenerator extends PdfGeneratorBase {
    static fonts = [
        'Raleway-Regular',
        'Raleway-Medium',
        'Raleway-Bold',
        'Raleway-ExtraBold',
    ]

    pageHeight = 745;

    constructor(public report: SalesPrepReport, public userProfile: UserProfile | undefined) {
        super();
    }
    
    async generate(name: string, title: string, color: string, icon: string, iconWidth: number, iconHeight: number, sections: IReportPart[], iconY: number = 0) {

        // let y = 167.964;
        let y = new MainHeaderSection(title, color, icon, iconWidth, iconHeight, iconY).draw(this);        
        sections.forEach(s => y = this._drawPart(s, y));
        
        new HeaderSection(title).draw(this);
        new FooterSection().draw(this);

        const _name = name.replace(/\s+/g, ' ').replace(/[^a-z0-9 ]/gi, '');
        const date = new DatePipe('en-US').transform(new Date());
        const filename = `SalesPrep ${title} for ${_name} - ${date}.pdf`;
        this.doc.save(filename);
    }

    private _drawPart(part: IReportPart, _y: number) {
        // const margin = 20;
        if (_y + 40 > this.pageHeight) {
            _y = this.addPage();
        }
        
        const y = part.draw(_y);
        
        if (y > 0) {
            // if (y + 40 < this.pageHeight) {
            //     return 0;
            // }
            return y;
        }
        return _y;
    }
    
    private _subHeadingMargin = 16.543;
    
    subHeading(y: number, text: string) {
        // const x = this.styles.paddingX;
        this.text(text, 58.457, y, 'Raleway-ExtraBold', 13);
        return y + this.doc.getLineHeight() + this._subHeadingMargin;
    }
    
    subHeadingHeight() {
        this.doc.setFontSize(13);
        this.doc.setFont("Raleway-ExtraBold");
        return this.doc.getLineHeight() + this._subHeadingMargin;
    }

}
