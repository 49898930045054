import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    HostBinding,
    Input,
    OnChanges,
    Output,
    SimpleChanges
} from '@angular/core';
import {TutorChatMessage} from "../../../../model";
import {UserAvatarComponent} from "../../../../components/user-avatar/user-avatar.component";
import {TutorAvatarComponent} from "../../../../components/tutor-avatar/tutor-avatar.component";

@Component({
    selector: 'app-chat-message',
    standalone: true,
    imports: [
        UserAvatarComponent,
        TutorAvatarComponent
    ],
    templateUrl: './chat-message.component.html',
    styleUrl: './chat-message.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatMessageComponent implements OnChanges {
    
    @Input() userInitials = '';
    @Input() message!: TutorChatMessage;
    @Input() speakingSentenceIndex: number | undefined = -1;
    @Input() sentences: string[] | undefined = [];
    @Output() onMessageSelected = new EventEmitter<string>();

    @Input() @HostBinding('class.novice') novice = false;
    @Input() @HostBinding('class.intermediate') intermediate = false;
    @Input() @HostBinding('class.expert') expert = false;
    
    ngOnChanges() {
        
    }

    onClick(event: MouseEvent, text: string) {
        if (event.ctrlKey) {
            // if (event.ctrlKey && text.indexOf('"') !== -1) {
            this.onMessageSelected.emit(text);
        }
    }
    
    
}
