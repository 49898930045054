import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'ordinal',
    standalone: true
})
export class OrdinalPipe implements PipeTransform {

    transform(num: number, ...args: unknown[]): unknown {
        const absNum = Math.abs(num);
        const lastTwoDigits = absNum % 100;
        const lastDigit = absNum % 10;

        let suffix = 'th';

        if (lastTwoDigits < 11 || lastTwoDigits > 13) {
            switch (lastDigit) {
                case 1:
                    suffix = 'st';
                    break;
                case 2:
                    suffix = 'nd';
                    break;
                case 3:
                    suffix = 'rd';
                    break;
            }
        }

        return `${num}${suffix}`;        
    }

}
