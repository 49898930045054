<app-main-header class="sp-desktop"/>

<app-mobile-nav-header class="mobile-layout" [title]="stepTitle()" (click)="back()" />

<div class="side-progress-bar-wrapper sp-wrapped">
    @if (reportData()) {
        <app-side-progress-bar ngSkipHydration
                [company]="reportData()!.company"
                [offering]="offering()"
                [job]="reportData()!.job"
                [meetingDate]="meetingDate()"
                [meetingTime]="reportData()!.meetingTime"
                [callReportMode]="true"
                [completedSteps]="data()?.step || 1"                               
                [step]="stepIndex"/>
    }
</div>

<div>
    <router-outlet></router-outlet>
</div>
    
<app-footer-smart/>