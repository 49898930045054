<div class="header">
    <a (click)="previousMonth()">
        <span class="material-symbols-outlined">arrow_back_ios</span>
    </a>
    <div>{{ getCurrentMonthName() }} {{ currentYear }}</div>
    <a (click)="nextMonth()">
        <span class="material-symbols-outlined">arrow_forward_ios</span>
    </a>
</div>

<div class="calendar-header">
<!--        @for (day of ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']; track day) {-->
    @for (day of ['S', 'M', 'T', 'W', 'T', 'F', 'S']; track day) {
        <div class="cell">
            <span>{{ day }}</span>
        </div>
    }
</div>

<div class="calendar">

    @for (week of weeks; track week) {    
        <div class="week">
            @for (day of week; track $index) {
                <div (click)="selectDay(day)" [class.disabled]="day.past && !allowPastDates" [class.selected]="day.selected" class="cell">                    
                    <span>{{ day.date.getDate() }}</span>
                </div>
            }
        </div>
    }
</div>
