import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

@Component({
    selector: 'app-mobile-nav-header',
    standalone: true,
    imports: [],
    templateUrl: './mobile-nav-header.component.html',
    styleUrl: './mobile-nav-header.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MobileNavHeaderComponent {
    @Input() title: string | undefined;

}
