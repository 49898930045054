import {CallReportVoiceInputStep} from "./model";
import {CallReportVoiceInputSteps} from "./call-report-voice-input-steps";
import {CallReportEditData} from "../../model";
import {OrdinalLongPipe} from "../../pipes/ordinal-long.pipe";

/*
export class IntervieweesCountStep extends CallReportVoiceInputStep {
    constructor() {
        super(
            CallReportVoiceInputSteps.IntervieweesCount,
            'How many customers did you meet with?'
        );
    }

    override complete(data: CallReportEditData, transcript: string): CallReportVoiceInputSteps {        
        return CallReportVoiceInputSteps.IntervieweesCount;
    }
}
*/

export class IntervieweesStep extends CallReportVoiceInputStep {
    constructor() {
        super(
            CallReportVoiceInputSteps.IntervieweesStep,
            ''
        );
    }
    
    override formatText(data: CallReportEditData): string {
        const num = this._toOrdinal(data.interviewees.length + 1);
        
        return !data.interviewees.length
            ? `What was the name and role of the ${num} customer you met?`
            : `What was the name and role of the ${num} customer?`
    }

    override complete(data: CallReportEditData, transcript: string, index: number): CallReportVoiceInputSteps {
        if (!transcript) {
            return this.step + 1;
        }
        const list = data.interviewees;
        const i = index;
        if (!list[i]) {
            list[i] = {name: '', title: ''};
        }
        list[i].name = transcript;
        return this.step;
    }
    
    private _toOrdinal(num: number) {
        return new OrdinalLongPipe().transform(num);
    }
}