import {IReportPart} from "../pdf-export/ireport-part";
import {CallReportPdfGenerator} from "./call-report-pdf-generator";
import {TextSection} from "../pdf-export/text-section";


export class CallReportPdfSummarySection implements IReportPart {
    marginLeft = 58.457;
    marginBottom = 10;

    private sections: TextSection[];

    constructor(private pdf: CallReportPdfGenerator, private title: string, private summary: string) {
        const maxWidth = pdf.styles.pageInnerWidth;
        const paragraphs = this.summary.split('\n');

        this.sections =
            [
                pdf.createSection(title, maxWidth, 'Raleway-ExtraBold', 13),
                ...paragraphs.map(x => pdf.createSection(x, maxWidth, 'Raleway-Regular', 9))
            ];
    }

    draw(y: number) {
        
        const pdf = this.pdf;

        this.sections.forEach(x => {
            if (y + x.getHeight(this.pdf) > pdf.pageHeight) {
                pdf.doc.addPage();
                y = pdf.styles.paddingY;
            }
            y = x.draw(pdf, this.marginLeft, y) + this.marginBottom;
        });


        return y + 28.964;
    }
}