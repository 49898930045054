<div class="sp-checkbox title">
    <input type="checkbox" [disabled]="loading || !topics.length" [class.indeterminate]="indeterminate" [checked]="allSelected && !indeterminate" id="topics-{{index}}" (change)="toggleSelection()">
    <label for="topics-{{index}}"><strong qa="tit">{{title}}</strong>&nbsp; ({{topics.length}})</label>
    
    @if (!loading) {
        <!--<app-rate-section [rating]="rating" (ratingChange)="onRatingChange.emit($event)" qa="rat"/>-->
    }
</div>

<div class="items">        
    @if (loadingFailed) {
        <app-retry (onRetry)="onRetry.emit()"/>
    } @else {
        
        @for (x of topics; track x.title; let i = $index ) {
            <app-discussion-topic (onChange)="init()" (onClick)="onSelect.emit(x)" [attr.qa]="'tp-' + (index + 1)+'-'+(i + 1)" [index]="index + '-' + i" [topic]="x" [inline]="inline" [animated]="animated"/>
        } @empty {
            @if (!loading && newsMode) {
                <div class="no-items" qa="empty">Sorry, we couldn't find any related news at this time.</div>
            }
        }
        
        @if (loading) {
            <app-discussion-topic [index]="index + '-99999'" [topic]="emptyTopic"/>
        }
    }
</div>

<app-rate-section-footer [rating]="rating" (ratingChange)="onRatingChange.emit($event)" />