import {ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output} from '@angular/core';
import {TutorAvatarComponent} from "../../../../components/tutor-avatar/tutor-avatar.component";
import {RouterLink} from "@angular/router";
import {UserAvatarComponent} from "../../../../components/user-avatar/user-avatar.component";
import {TryAiGuideComponent} from "../../try-ai-guide.component";
import {TryAiGuideFormComponent, TryAiGuideFormData} from "../try-ai-guide-form/try-ai-guide-form.component";

@Component({
    selector: 'app-pure-try-ai-guide',
    standalone: true,
    imports: [
        TutorAvatarComponent,
        RouterLink,
        UserAvatarComponent,
        TryAiGuideFormComponent
    ],
    templateUrl: './pure-try-ai-guide.component.html',
    styleUrl: './pure-try-ai-guide.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PureTryAiGuideComponent {
    
    @Input() userInitials = 'I';
    @Input() offering = '';
    @Input() job = '';
    @Input() message = '';
    @Input() authenticated = false;

    @Output() onSubmit = new EventEmitter<TryAiGuideFormData>();
    
    @HostBinding('class.sp-typography') t = true;

}
