import {Component, computed, HostBinding, Input, signal} from '@angular/core';
import {ReportBuilderService} from "../../../../services/report-builder.service";
import {ProductsService} from "../../../../services/products.service";
import {Router} from "@angular/router";
import {AuthService} from "../../../../services/auth.service";
import {Offering} from "../../../../model/offering";
import {PureSelectOfferingComponent} from "./components/pure-select-offering/pure-select-offering.component";
import {OfferingCategory} from "../../../../model";
import {OfferingType} from "../../../../model/offeringType";
import {MessageService} from "../../../../services/message.service";
import {RemoveJobArgs} from "../../../profile/components/pure-add-offering/pure-add-offering.component";

@Component({
    selector: 'app-select-product',
    standalone: true,
    imports: [
        PureSelectOfferingComponent
    ],
    templateUrl: './select-offering.component.html',
    styleUrl: './select-offering.component.scss'
})
export class SelectOfferingComponent {
    
    company = this.report.company;
    offerings = this.authService.offerings;
    // categories: OfferingCategory[] = [];
        
    @HostBinding('class.sp-screen') s = true;

    type = signal<OfferingType>(OfferingType.MaterialOrIngredientOrComponent);
    offeringTypes = this.productsService.offeringTypes;
    categories = computed<OfferingCategory[]>(() => {
        return this.offeringTypes().find(x => x.id == this.type())?.categories || [];        
    })
    
    constructor(private report: ReportBuilderService, 
                private router: Router,
                private messageService: MessageService,
                private productsService: ProductsService,
                private authService: AuthService) {
    }

    select(p: Offering) {
        this.report.offering.set(p);
        this.router.navigate(['/report-builder', 'job']);    
    }

    addOffering(offering: Offering) {
        this.productsService.addOffering(offering)
            .subscribe(() => {
                console.log('[SelectOffering] addOffering success');
                this.select(offering);
            });
    }

    setCategories(type: OfferingType) {
        this.type.set(type);
    }

    removeJob(args: RemoveJobArgs) {
        const { offering: o, jobIndex: index } = args;

        if (!o) return;

        if (o.jobs[index]) {
            this.messageService.confirm('You are about to remove job. Are you sure you want to proceed?',
                () => o.jobs = o.jobs.filter((x, i) => i != index));
        } else {
            o.jobs = o.jobs.filter((x, i) => i != index);
        }
    }
}
