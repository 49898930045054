import {ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {PageHeaderComponent} from "../../../../components/page-header/page-header.component";
import {
    ReportGenerationIndicatorComponent
} from "../../../report-viewer/components/report-generation-indicator/report-generation-indicator.component";

@Component({
    selector: 'app-pure-brief-report-download',
    standalone: true,
    imports: [
        PageHeaderComponent,
        ReportGenerationIndicatorComponent
    ],
    templateUrl: './pure-brief-report-download.component.html',
    styleUrl: './pure-brief-report-download.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PureBriefReportDownloadComponent {
    
    @Input() generating = true;
    
    @Input() title = '';
}
