<a (click)="set(true)" class="option" [class.selected]="voiceInput">
    <div class="icon">
        <span class="material-symbols-outlined">mic</span>
    </div>
    <div class="text" qa="voice">
        <div class="title">Voice</div>
        <div qa="dsc">Listen &amp; Speak</div>
    </div>
</a>
<a (click)="set(false)" class="option" [class.selected]="!voiceInput">    
    <div class="icon">
        <span class="material-symbols-outlined">keyboard</span>
    </div>
    <div class="text" qa="txt">
        <div class="title">Text</div>
        <div qa="dsc">Read & Type</div>
    </div>
</a>