import {computed, effect, Injectable, signal} from '@angular/core';
import {Roles} from "../../model";
import {CallReportBuilderService} from "../call-report-builder.service";
import {SpeechSynthesisService} from "../speech-synthesis.service";
import {SpeechRecognitionService} from "../speech-recognition.service";
import {CallReportVoiceInputSteps} from "./call-report-voice-input-steps";
import {CallReportVoiceInputStepsList} from "./call-report-voice-input-steps-list";
import {CallReportVoiceInputStepOptions, CallReportVoiceInputStepWithOptions} from "./model";

@Injectable({
    providedIn: 'root'
})
export class CallReportVoiceInputService {

    steps = CallReportVoiceInputStepsList.steps;
    
    currentStep = signal(this.steps[0]);
    currentStepIndex = signal(0);
    
    currentStepText = computed(() => {
        const step = this.currentStep();
        const data = this.reportBuilder.inputData()!;
        return step.formatText(data, this.currentStepIndex());
    });
    
    currentStepValue = effect(() => {
        const step = this.currentStep();
        const data = this.reportBuilder.inputData()!;
        this.speechRecognitionService.transcript.set(step.getValue(data));
    }, { allowSignalWrites: true });
    
    currentStepOptions = computed(() => {
        const step = this.currentStep() as CallReportVoiceInputStepWithOptions;
        return step?.options;
    })

    onCurrentStepUpdate = effect(() => {
        console.log('[callReportVoiceInputService] onCurrentStepUpdate');
        const voice = this.reportBuilder.voiceInput();
        if (voice) {
            this.speak();
        } else {
            this.speechSynthesisService.stop();
        }
    },  {allowSignalWrites: true});
    
    onTranscriptUpdate = effect(() => {
        const t = this.speechRecognitionService.transcript().toLowerCase();
        
        const s = this.currentStep();
        const step = s as CallReportVoiceInputStepWithOptions;
        if (step?.options) {
            for (const o of step.options) {
                const selected = !!t && t.startsWith(o.transcript);
                if (selected) {
                    console.log('[CallReportVoiceInputService] onTranscriptUpdate - selected option');
                    this.speechRecognitionService.stop();
                    this.select(o);
                    setTimeout(() => this.next(''), 1000);
                } else {
                    o.selected = false;
                }
                
            }
        }
    },  {allowSignalWrites: true});
    
    constructor(private reportBuilder: CallReportBuilderService, 
                private speechSynthesisService: SpeechSynthesisService,
                private speechRecognitionService: SpeechRecognitionService) {
        const step = sessionStorage.getItem('voice-input-step');
        if (step) {
            this.currentStep.set(this.steps[+step]);
        }
    }

    setMeetingOverviewStep() {
        console.log('[callReportVoiceInputService] setMeetingOverviewStep');
        
        this.currentStep.set(this.steps[0]);
    }
    
    setOtherThingsStep(i: number) {
        console.log('[callReportVoiceInputService] setOtherThingsStep');
        
        const index = CallReportVoiceInputSteps.Insights + (i - 1);
        this.currentStep.set(this.steps[index]);
    }
    
    next(text: string) {
        console.log('[callReportVoiceInputService] next');

        this.speechRecognitionService.stop();
        this.speechRecognitionService.transcript.set(' ');
        this.speechRecognitionService.transcript.set('');

        const s = this.currentStep();
        if (s.step < CallReportVoiceInputSteps.Notes) {
            const index = this._do((text?? '').trim());
            
            if (s.step == index) {
                this.currentStepIndex.update(x => x + 1);
            } else {
                this.currentStepIndex.set(0);
                this._go(index);
            }
        }
    }
    
    prev() {
        console.log('[callReportVoiceInputService] prev');
        
        const step = this.currentStep().step;
        if (step > 0) {
            
            if (this.currentStepIndex() > 0) {
                this.currentStepIndex.update(x => x - 1);
            } else {
                this._go(step - 1);
            }
        }
    }

    select(option: CallReportVoiceInputStepOptions) {
        const s = this.currentStep();
        (s as CallReportVoiceInputStepWithOptions)?.select(option);            
    }
    
    async speak() {
        console.log('[callReportVoiceInputService] speak');
        
        await this.speechSynthesisService.speak(this.currentStepText(), Roles.Coach);
        const r = this.speechRecognitionService;
        if (!r.recording()) {
            r.start();
        }
    }
    
    private _go(index: number) {
        console.log('[callReportVoiceInputService] _go: ', index);
        this.currentStep.set(this.steps[index]);
        sessionStorage.setItem('voice-input-step', index + '');
    }

    private _do(text: string): CallReportVoiceInputSteps {
        const data = this.reportBuilder.inputData();
        if (!data) throw Error('[CallReportVoiceInputService] no data');
        
        const s = this.currentStep();
        if (text == 'next' || text == 'skip') {
            return s.step + 1;
        }
        return s.complete(data, text, this.currentStepIndex());
    }
}
