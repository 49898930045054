import {ChangeDetectionStrategy, Component} from '@angular/core';

@Component({
    selector: 'app-recording-animation',
    standalone: true,
    imports: [],
    templateUrl: './recording-animation.component.html',
    styleUrl: './recording-animation.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RecordingAnimationComponent {

}
