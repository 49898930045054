<div class="wrapper">
    <div>
        <a class="title" title="Home" [routerLink]="['/']" qa="title-home">
            <div class="main">Sales<em>Prep</em><span class="tm">TM</span></div>
            <div class="copy">Created by The AIM Institute</div>
        </a>        
    
        <div>© 2025 The AIM Institute.</div> 
        <div>All Rights Reserved.</div>
    
    </div>
    
    <div class="links">
        
        <div class="links-group">
            <div class="group">
                <a routerLink="/">Home</a>
                <a routerLink="/try-ai-guide">Try Role-play Practice Session</a>
                <a target="_blank" href="https://www.blueprintingcenter.com/public_downloads/7251c6e0-c933-4050-ab00-e4d4e6042789" fragment="aiguide">Download Sample Report</a>
                <a target="_blank" href="https://theaiminstitute.com" title="The AIM Institute - New Product Blueprinting and Everyday VOC">The AIM Institute</a>
                <a href="https://www.salespreppractice.com">SalesPrepPractice.com</a>
            </div>

            <div class="group">
                <a routerLink="/signup/0">Start Free Trial</a>
                <a routerLink="/signup/1">Start Month-to-Month Plan </a>
                <a routerLink="/signup/2">Start Annual Plan</a>
                <a routerLink="/signup/3">Everyday VOC Workshop</a>
                <a routerLink="/login">Login</a>
            </div>
        </div>
        
        <div class="group">
            <a routerLink="/terms">Terms</a>
            <a routerLink="/privacy">Privacy</a> 
            <a routerLink="/cookie-policy">Cookie Policy</a> 
            <a routerLink="/faq">FAQs</a>
            <a routerLink="/contact-us">Contact Us</a>
            
        </div>
    </div>
</div>
