import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'indexToLetter',
    standalone: true
})
export class IndexToLetterPipe implements PipeTransform {
    transform(index: any, ...args: unknown[]): string {
        return String.fromCharCode(96 + +index); // let's show 3a, 3b, etc.
    }

}
