<div class="flex-column container">
    <app-main-header class="sp-desktop" [sticky]="false"/>

    @if (reportIsMissing()) {
        <app-page-header [title]="'Unable to load report'" [text]="authenticated() ? 'Please try to reload the page' : 'Please try to login'"/>
    } @else {
        <app-pure-report-viewer
            (onSave)="updateReport()"
            (onSaveMeetingDate)="updateMeetingDate($event)"
            (onSaveExploratoryQuestions)="saveExploratoryQuestions($event)"
    
            (onCancelEditMode)="cancelEditMode()"
            (onRatingChange)="rate($event)"
            (onScrollToTop)="scrollToTop(true)"
    
            (onExportToPdf)="cancelEditMode(); export()"
            (onExportCustomerHandouts)="cancelEditMode(); exportCustomerHandouts($event)"
            (onEditDiscussionTopics)="cancelEditMode(); editDiscussionTopics();scrollToTop()"
            (onEditMeetingDate)="cancelEditMode(); editMeetingDate = true;scrollToTop()"
            (onEditExploratoryQuestions)="cancelEditMode(); editExploratoryQuestions = true;scrollToTop()"

            (onJobDescriptionReport)="jobDescriptionReport($event)"
            (onTrendsBriefReport)="trendsBriefReport($event)"
            (onProblemsBriefReport)="problemsBriefReport($event)"
            (onProcessBriefReport)="processBriefReport($event)"
            (onRolePlay)="rolePlay($event)"
            
            (openAIGuide)="navigateToTutor()"
    
            [authenticated]="authenticated()"
            [exporting]="exporting()"
            [reportDataLoading]="reportDataLoading()"
            [reportData]="reportData()"
            [company]="company()"
            
            [exploratoryQuestions]="exploratoryQuestions()"
            [stakeholders]="stakeholders()"
            [newsArticles]="newsArticles()"
            [discussionTopics]="discussionTopics()"
            [discussionTopicsEditable]="discussionTopicsEditable()"
            [otherTopics]="otherTopics()"
    
            [ratings]="ratings()"

            [editMeetingDate]="editMeetingDate"
            [editExploratoryQuestions]="editExploratoryQuestions"
        />
    }
    
</div>

@if (badReportSectionRating) {
    <app-info-panel [title]="'Reason for your rating'" (onClose)="badReportSectionRating = undefined">
        <app-rate-section-dialog
                (onCancel)="badReportSectionRating = undefined"
                (onSubmit)="rate($event, true)"
                [rating]="badReportSectionRating!"
        />
    </app-info-panel>
}

<app-footer-smart/>