import * as step from './steps';
import {CallReportVoiceInputStep} from "./model";
import * as interviewers from "./interviewers-steps";
import * as interviewees from "./interviewees-steps";
import * as outcomes from "./outcomes-steps";

export class CallReportVoiceInputStepsList {
    
    static steps: CallReportVoiceInputStep[] = [
        new step.StartCallReportStep(),
        new step.CustomerLocationStep(),
        new step.MeetingTypeStep(),
        new step.ScopeReviewStep(),
        new step.ScopeUpdateStep(),
        
        new interviewers.InterviewersStep(),
        new interviewers.InterviewersNamesStep(),
        
        // new interviewees.IntervieweesCountStep(),
        new interviewees.IntervieweesStep(),
        
        new step.ReviewMeetingOverviewQuestionStep(),
        new step.ReviewMeetingOverviewStep(),
        
        new outcomes.OutcomesCountStep(),
        new outcomes.OutcomeWhatStep(),
        new outcomes.OutcomeWhyStep(),
        new outcomes.OutcomeClarifyStep(),        
        
        new step.InsightsStep(),
        new step.OpportunitiesStep(),
        new step.NextStepsStep(),
        new step.NotesStep(),
    ];    
}
