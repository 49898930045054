import {CallReportVoiceInputStep, CallReportVoiceInputStepWithOptions} from "./model";
import {CallReportVoiceInputSteps} from "./call-report-voice-input-steps";
import {CallReportEditData} from "../../model";
import {OrdinalLongPipe} from "../../pipes/ordinal-long.pipe";

export class OutcomesCountStep extends CallReportVoiceInputStepWithOptions {
    constructor() {
        super(
            CallReportVoiceInputSteps.OutcomesCount,
            'Did you uncover any desired customer outcomes?\n\nThese could be problems they want fixed or improvements they’d like.',
            [
                { title: '<em>Yes</em>.', transcript: 'yes' },
                { title: '<em>No</em>. Skip this step.', transcript: 'no' },
            ]
        );
    }

    override complete(data: CallReportEditData, transcript: string): CallReportVoiceInputSteps {
        if (this.options[0].selected) {
            data.outcomes = [{why: '', what: '', clarify: ''}];
            return CallReportVoiceInputSteps.OutcomeWhat;
        } else if (this.options[1].selected) {
            data.outcomes = [];
            return CallReportVoiceInputSteps.Insights;
        }
        return this.step;
    }
}

export class AdditionalOutcomesStep extends CallReportVoiceInputStepWithOptions {
    constructor() {
        super(
            CallReportVoiceInputSteps.AdditionalOutcomes,
            'Did you uncover any additional customer outcomes?',
            [
                { title: '<em>Yes</em>.', transcript: 'yes' },
                { title: '<em>No</em>.', transcript: 'no' },
            ]
        );
    }

    override complete(data: CallReportEditData, transcript: string): CallReportVoiceInputSteps {
        if (this.options[0].selected) {
            data.outcomes.push({ why: '', what: '', clarify: ''});
            return CallReportVoiceInputSteps.OutcomeWhat;
        } else if (this.options[1].selected) {
            return CallReportVoiceInputSteps.Insights;
        }
        return this.step;
    }
}

export class OutcomeWhatStep extends CallReportVoiceInputStep {
    constructor() {
        super(
            CallReportVoiceInputSteps.OutcomeWhat,
            ''
        );
    }

    override formatText(data: CallReportEditData): string {
        const num = this._toOrdinal(data.outcomes.length);

        return data.interviewees.length == 1
            ? `Can you describe it?`
            : `Can you describe the ${num} outcome?`
    }

    override complete(data: CallReportEditData, transcript: string): CallReportVoiceInputSteps {
        if (!transcript) return this.step;
        data.outcomes[data.outcomes.length - 1].what = transcript;
        return CallReportVoiceInputSteps.OutcomeWhy;
    }

    private _toOrdinal(num: number) {
        return new OrdinalLongPipe().transform(num);
    }
}


export class OutcomeWhyStep extends CallReportVoiceInputStep {
    constructor() {
        super(
            CallReportVoiceInputSteps.OutcomeWhy,
            'How does it impact them?'
        );
    }

    override complete(data: CallReportEditData, transcript: string): CallReportVoiceInputSteps {
        data.outcomes[data.outcomes.length - 1].why = transcript;
        return CallReportVoiceInputSteps.OutcomeClarify;
    }
}

export class OutcomeClarifyStep extends CallReportVoiceInputStep {
    constructor() {
        super(
            CallReportVoiceInputSteps.OutcomeClarify,
            'The customer would like to…'
        );
    }

    override complete(data: CallReportEditData, transcript: string): CallReportVoiceInputSteps {
        data.outcomes[data.outcomes.length - 1].clarify = transcript;
        return CallReportVoiceInputSteps.AdditionalOutcomes;
    }
}