<div class="flex container" qa="tutor-md">
    <div class="flex-column">
        <a class="close" (click)="flyaway()">
            <i class="material-icons">close</i>
        </a>
        <div class="title bold">
            @switch (mode) {
                @case (TutorPanelStyle.Novice) {
                    Do you ask great questions?
                }
                @case (TutorPanelStyle.Intermediate) {
                    Have you mastered the best probing techniques?
                }
                @case (TutorPanelStyle.Expert) {
                    Do you effectively probe for problems in this process?
                }
                @case (TutorPanelStyle.Custom) {
                    Do your questions demonstrate interest in your customer?
                }
            }
        </div>
        <div class="label">
            @if (mode == TutorPanelStyle.Custom) {
                Practice this interview with Claire to find out.
            } @else {
                Practice this interview with Claire, your AI Guide, to find out.
            }
        </div>
    </div>
    <div class="flex-column clare">
        <app-tutor-avatar (click)="flyin()"/>
        <div class="avatar-background"></div>
        <a class="sp-link bold underline" (click)="start.emit()" qa="role-play">Role-play with Claire</a>
    </div>
</div>