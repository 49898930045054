<div #header class="header">
    <app-report-header
            (onScrollToTop)="onScrollToTop.emit()"
            (onExportToPdf)="onExportToPdf.emit()"
            (onExportCustomerHandouts)="onExportCustomerHandouts.emit($event)"
            (onEditExploratoryQuestions)="onEditExploratoryQuestions.emit()"
            (onEditDiscussionTopics)="onEditDiscussionTopics.emit()"
            (onEditMeetingDate)="onEditMeetingDate.emit()"            
            [authenticated]="authenticated"
            [exporting]="exporting"            
            [reportData]="reportData" [company]="company" />

    @if (editMode) {
        <a class="sp-link" (click)="onCancelEditMode.emit()" qa="cancel-edot">Cancel edit mode</a>
        <br>
    } @else {
        <div class="sections">
            <a (click)="scrollToSection(news)" [class.active]="activeSection === 'news'" qa="news"><span class="nobr">News &</span> Trends</a>
            <a (click)="scrollToSection(problems)" [class.active]="activeSection === 'problems'" qa="prob">Common Problems</a>
            <a (click)="scrollToSection(process)" [class.active]="activeSection === 'process'" qa="steps">Process Steps</a>
            <a (click)="scrollToSection(companyOverview)" [class.active]="activeSection === 'companyOverview'" qa="overview">Company Overview</a>
        </div>
    }    
</div>

@if (editExploratoryQuestions) {
    <app-pure-exploratory-questions
            [inlineMode]="true"
            [questions]="exploratoryQuestions"
            (onBack)="onCancelEditMode.emit()"
            (onNext)="onSaveExploratoryQuestions.emit($event)"
    
    />
} @else if (discussionTopicsEditable) {    
    <app-pure-select-discussion-topics
            (onCancelEditMode)="onCancelEditMode.emit()"
            (onNext)="onSave.emit()"
            [inlineMode]="true"
            [company]="company"
            [newsArticles]="newsArticles"
            [stakeholdersEnabled]="stakeholders.length > 0"
            [stakeholders]="stakeholders"
            [discussionTopics]="discussionTopicsEditable"
            [otherTopics]="otherTopics"

            [companyNewsRating]="companyNewsRating"
            [stakeholdersRating]="stakeholdersRating"
            [marketTrendsRating]="marketTrendsRating"
            [commonProblemsRating]="commonProblemsRating"
            [processStepsRating]="processStepsRating"
            [otherTopicsRating]="otherTopicsRating"

            [selectedCustomDiscussionTopic]="selectedCustomDiscussionTopic"
            
            (onAddDiscussionTopic)="addDiscussionTopic()"
            (onSaveCustomTopic)="saveCustomTopic($event)"
            (onRatingChange)="rate($event)"
    />
} @else if (editMeetingDate) {
    <app-pure-set-meeting-date
            (onCancelEditMode)="onCancelEditMode.emit()"
            (onSave)="onSaveMeetingDate.emit($event)"
            [inlineMode]="true"
            [date]="meetingDate"
            [time]="meetingTime"
    />
} @else {
    <div class="container" [class.loading]="reportDataLoading">
    
        @if (reportData) {
            
            <app-trends-header #news />
            
            @if (exploratoryQuestionsSelected.length) {
                <app-exploratory-questions [exploratoryQuestions]="exploratoryQuestionsSelected" [rating]="exploratoryQuestionsRating" (onRatingChange)="rate($event)" />
            }
            
            @if (stakeholdersSelected.length) {
                <app-stakeholders-list [stakeholders]="stakeholdersSelected" [rating]="stakeholdersRating" 
                                       (onGenerateReport)="onJobDescriptionReport.emit($event)"
                                       (onRatingChange)="rate($event)" qa="stakeholders" />
            }
            
            @if (newsArticlesSelected.length) {
                <app-news-section [news]="newsArticlesSelected" [rating]="companyNewsRating" (onRatingChange)="rate($event)" qa="news"/>
            }
                       
            
            <app-discussion-topics-list-by-role [icon]="1" [title]="'MARKET TRENDS'" [topics]="discussionTopics" [rating]="marketTrendsRating"
                                                (onCustomerHandout)="exportCustomerHandout(1)"
                                                (onBriefReport)="onTrendsBriefReport.emit($event)"
                                                (onRolePlay)="onRolePlay.emit($event)"
                                                (onJobDescription)="onJobDescriptionReport.emit($event)"
                                                (onRatingChange)="rate($event)" [attr.qa]="'comp-tr'"/>

            <app-problems-header #problems />
            <app-discussion-topics-list-by-role [icon]="2" [title]="'COMMON PROBLEMS'" [topics]="discussionTopics" [rating]="commonProblemsRating"
                                                (onCustomerHandout)="exportCustomerHandout(2)"
                                                (onBriefReport)="onProblemsBriefReport.emit($event)"
                                                (onRolePlay)="onRolePlay.emit($event)"
                                                (onJobDescription)="onJobDescriptionReport.emit($event)"
                                                (onRatingChange)="rate($event)" [attr.qa]="'comp-pr'"/>

            <app-process-steps-header #process />
            <app-discussion-topics-list-by-role [icon]="3" [title]="'PROCESS STEPS'" [topics]="discussionTopics" [rating]="processStepsRating"
                                                (onCustomerHandout)="exportCustomerHandout(3)"
                                                (onBriefReport)="onProcessBriefReport.emit($event)"
                                                (onRolePlay)="onRolePlay.emit($event)"
                                                (onJobDescription)="onJobDescriptionReport.emit($event)"
                                                (onRatingChange)="rate($event)" [attr.qa]="'comp-ste'"/>
                
            <app-discussion-topics-list [flat]="true" [icon]="4" [title]="'OTHER TOPICS'" [topics]="otherTopics" [rating]="otherTopicsRating" (onRatingChange)="rate($event)" qa="comp-ot"/>
            
    <!--        <app-other-topics-list [topics]="" />-->
    
            <div class="company-overview-wrapper" #companyOverview>
                <app-company-overview
                        (onRatingChange)="rate($event)"
                        [company]="company"
                                      
                        [companyDescriptionRating]="companyDescriptionRating"
                        [companySizeRating]="companySizeRating"
                        [leadershipRating]="leadershipRating"
                        [productsRating]="productsRating"
                        [competitorsRating]="competitorsRating"
                        [strategyRating]="strategyRating"                                          
                    />
            </div>

            <div class="ai-disclaimer">This work product has been generated by AI and should be reviewed carefully.</div>

            <app-tutor-panel class="desktop tutor-1" [mode]="TutorPanelStyle.Novice" (start)="openAIGuide.emit(true)" />
            <app-tutor-panel class="desktop tutor-2" [mode]="TutorPanelStyle.Intermediate" (start)="openAIGuide.emit(true)" />
            <app-tutor-panel class="desktop tutor-3" [mode]="TutorPanelStyle.Expert" (start)="openAIGuide.emit(true)" />
            <app-tutor-panel class="desktop tutor-4" [mode]="TutorPanelStyle.Custom" (start)="openAIGuide.emit(true)" />
            
            <app-tutor-panel class="mobile" [flying]="true" (start)="openAIGuide.emit()" />
    
        } @else {
            <app-spinner></app-spinner>
        }
    </div>
}
