import {Component, HostBinding, Input} from '@angular/core';

@Component({
    selector: 'app-spinner',
    standalone: true,
    imports: [],
    templateUrl: './spinner.component.html',
    styleUrl: './spinner.component.scss'
})
export class SpinnerComponent {
    @HostBinding('class.done') @Input() done = false;
    @HostBinding('class.centered') @Input() centered = false;
}
