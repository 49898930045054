<div class="actions" qa="act">
    <app-rounded-button class="desktop-layout"
                        [routerLink]="['/report-builder', 'new']"
                        [icon]="'add'"
                        [text]="'Create new report'"
    />
    
    <app-rounded-button class="mobile-layout"
                        (click)="showSort = true"
                        [text]="sortTitle"
            />
    <app-rounded-button
            (click)="showFilter = true"
            (onCancel)="resetFilter()"
            [icon]="'filter_list'" [text]="filtered ? 'FILTERED' : 'FILTER...'"
            [cancellable]="true"
            [cancellableTitle]="'Reset Filters'"
            [primary]="filtered" qa="filter"/>
</div>

<div class="row header" qa="head">
    <div></div>
    <div>
        <a (click)="toggleSort(1)" qa="lbl-co">
            Company
            @if (sortIndex == 1) {
                @if (sortDirection == 1) {
                    <span class="material-symbols-outlined icon">arrow_drop_up</span>
                } @else {
                    <span class="material-symbols-outlined icon">arrow_drop_down</span>
                }
            }
        </a>
    </div>
    <div>
        <a (click)="toggleSort(2)" qa="lbl-md">
            Meeting Date
            @if (sortIndex == 2) {
                @if (sortDirection == 1) {
                    <span class="material-symbols-outlined icon">arrow_drop_up</span>
                } @else {
                    <span class="material-symbols-outlined icon">arrow_drop_down</span>
                }
            }
        </a>
    </div>
    <div>
        <a (click)="toggleSort(3)" qa="lbl-mw">
            Meeting With
            @if (sortIndex == 3) {
                @if (sortDirection == 1) {
                    <span class="material-symbols-outlined icon">arrow_drop_up</span>
                } @else {
                    <span class="material-symbols-outlined icon">arrow_drop_down</span>
                }
            }
        </a>
    </div>
    <div>
        <a (click)="toggleSort(4)" qa="lbl-off">
            Offering
            @if (sortIndex == 4) {
                @if (sortDirection == 1) {
                    <span class="material-symbols-outlined icon">arrow_drop_up</span>
                } @else {
                    <span class="material-symbols-outlined icon">arrow_drop_down</span>
                }
            }
        </a>
    </div>
    <div>
        <a (click)="toggleSort(5)" qa="lbl-lw">
            Last Viewed
            @if (sortIndex == 5) {
                @if (sortDirection == 1) {
                    <span class="material-symbols-outlined icon">arrow_drop_up</span>
                } @else {
                    <span class="material-symbols-outlined icon">arrow_drop_down</span>
                }
            }
        </a>
    </div>
    <div>
        <a (click)="toggleSort(6)" qa="lbl-lm">
            Last Modified
            @if (sortIndex == 6) {
                @if (sortDirection == 1) {
                    <span class="material-symbols-outlined icon">arrow_drop_up</span>
                } @else {
                    <span class="material-symbols-outlined icon">arrow_drop_down</span>
                }
            }
        </a>
    </div>
    <div>
        <a (click)="toggleSort(7)" qa="lbl-cr">
            Call Report
            @if (sortIndex == 7) {
                @if (sortDirection == 1) {
                    <span class="material-symbols-outlined icon">arrow_drop_up</span>
                } @else {
                    <span class="material-symbols-outlined icon">arrow_drop_down</span>
                }
            }
        </a>
    </div>

</div>

@for (r of _sortedList; track r.id; let i = $index) {
    <div class="row data" [routerLink]="['/report', r.id]" [attr.qa]="'rep-' + (i + 1)">
        <div>
            <div class="logo" [style.background-image]="'url(' + r.company.logoUrl + ')'" qa="logo"></div>
        </div>
        <div class="company" qa="comp">{{ r.company.name }}</div>
        <div class="cell date">
            <span class="mobile-layout">MEETING DATE</span>
            @if (r.meetingDate) {
                <span qa="meet-date">{{ r.meetingDate | date }}</span>&nbsp;
                <span qa="meet-time">{{ r.meetingTime | time }}</span>
            } @else {
                <span class="na" qa="meet-date">Not Set</span>
            }
        </div>
        <div class="cell roles">
            <span class="mobile-layout">MEETING WITH</span>
            @if (!r.roles || !r.roles.length) {
                <span class="na" qa="meet-with">Not Set</span>
            } @else {
                <span qa="meet-with">{{ r.roles | roles }}</span>
            }
        </div>
        <div class="cell" qa="off-div">
            <span class="mobile-layout">OFFERING</span>
            <app-report-subject [offeringName]="r.offeringName" [offeringType]="r.offeringType" [job]="r.job" qa="app-rep-sub"/>
        </div>
        <div class="cell">
            <span class="mobile-layout">LAST VIEWED</span>
            <span qa="lv-div">{{ r.lastViewed | date }}</span>
        </div>
        <div class="cell">
            <span class="mobile-layout">LAST MODIFIED</span>
            <span qa="lm-div">{{ r.lastUpdated | date }}</span>
        </div>
        <div class="cell">
            <span class="mobile-layout">CALL REPORT</span>
            <span qa="cr-div">
                @if (!!r.callReport) {
                    <a class="sp-button blue" [routerLink]="['/call-report', r.id]" qa="o-cr">Open Report</a>
                } @else {
                    <a class="sp-button yellow" [routerLink]="['/call-report-builder', r.id, 'overview']" qa="w-cr">Write Report</a>
                }
            </span>
        </div>
        <div class="cell">
            <a class="remove" title="Click to remove report" (click)="remove($event, r)"><i class="material-icons menu-icon" qa="delete">delete</i></a>
        </div>
        
    </div>
} @empty {
    <div class="center" style="padding: 20px" qa="no-reports">There are no report to display.</div>
}

@if (showSort) {
    <app-info-panel [title]="'SORT...'" (onClose)="showSort = false">
        <app-reports-sort [sortIndex]="sortIndex" [sortDirection]="sortDirection"
                          (sortChange)="sort($event);showSort = false;"/>
    </app-info-panel>
}
@if (showFilter) {
    <app-info-panel [title]="'FILTER...'" (onClose)="showFilter = false" qa="filter">
        <app-reports-filter [filter]="reportsFilter" [reports]="reports"
                            (filterChange)="filter($event); showFilter = false;"/>
    </app-info-panel>
}