@if (voices.length) {
    <ul class="voices">
        @for (voice of voices; track voice.voiceURI; ) {
            <li title="Click to hear the voice" class="voice sp-selectable" [class.selected]="voice.voiceURI == playingVoice" (click)="onPlayVoice.emit(voice)">
                <div>
                    <span class="material-symbols-outlined">
                        @if (playingVoice == voice.voiceURI) {volume_off} @else {text_to_speech}
                    </span>
                </div>
                <div class="container">
                    <div class="title">{{ voice.name }}</div>
                    
                    <div class="roles">
                        <a (click)="select($event, voice, Roles.Coach)"    [title]="getPrefix(coachVoice == voice.voiceURI) + 'Claire\'s voice'" class="sp-link role" [class.selected]="coachVoice == voice.voiceURI">Claire</a>
                        <a (click)="select($event, voice, Roles.Customer)" [title]="getPrefix(customerVoice == voice.voiceURI) + 'Customer\'s voice'" class="sp-link role" [class.selected]="customerVoice == voice.voiceURI">Customer</a>
                        <a (click)="select($event, voice, Roles.User)"     [title]="getPrefix(userVoice == voice.voiceURI) + 'User\'s voice in Novice role-play session'" class="sp-link role" [class.selected]="userVoice == voice.voiceURI">User</a>
                    </div>
                    
                    <div class="uri">
                        {{ voice.voiceURI }}
                    </div>
                </div>
            </li>
        }
    </ul>
} @else {
    <div>Unable to load voices list for your browser.</div>            
}

<a (click)="onReset.emit()" class="sp-link reset" title="Reset settings to their defaults">Reset to default voices</a>