import {OfferingActionPipe} from "../../../pipes/offering-action.pipe";
import {CallReportPdfGenerator} from "../call-report-pdf-generator";

export class MainHeaderSection {
    
    constructor(private title: string, private color: string, private icon: string, private iconWidth: number, private iconHeight: number, private iconY = 0) {
    }

    
    draw(pdf: CallReportPdfGenerator) {
        const doc = pdf.doc;
        
        doc.setPage(1);

        pdf.doc.addImage(`assets/brief-reports/${this.icon}.png`, 'png', 0, this.iconY, this.iconWidth, this.iconHeight);

        const topBarHeight = 27.184;
        doc.setFillColor(this.color);
        doc.rect(0, 0, pdf.pageWidth / 2, topBarHeight, 'F');
        doc.setFillColor(pdf.styles.linkColor);
        doc.rect(pdf.pageWidth / 2, 0, pdf.pageWidth / 2, topBarHeight, 'F');

        // Sales (blue)
        doc.setTextColor(33, 150, 243);
        pdf.text("Sales", 454.855, 43.291 - 5, 'Raleway-Medium', 14);

        // Prep (orange)
        doc.setTextColor(255, 87, 34);
        // const text1Width = doc.getTextWidth("Sales");        
        pdf.text("Prep", 490.226, 43.291 - 5, 'Raleway-MediumItalic', 14);

        doc.setTextColor(33, 150, 243);

        // TM (smaller size and raised)        
        pdf.text("TM", 517.773, 38.307, 'Raleway-Medium', 5);

        // REPORT (blue)
        pdf.text("REPORT", 529.172, 43.462 - 3, 'Raleway-Medium', 10);
        
        
        doc.setTextColor(0, 0, 0);
        
        const x = 47.061;
        let y = 73.402;
        const w = pdf.text(this.title + ' for ', x, y, 'Raleway-Regular', 21);

        doc.setFont("Raleway-ExtraBold");
        const title = pdf.report.callReport!.customerCompany;
        
        const maxWidth = 464.707; // this.pdf.styles.pageWidth;
        const firstLineWidth = maxWidth - w;
        const lines = doc.splitTextToSize(title, firstLineWidth);
        const firstLine: string = lines[0];
        const remainingText = title.substring(firstLine.length).trim();

        pdf.text(firstLine, x + w, y);
        y = pdf.text(remainingText, x, y + doc.getLineHeight() * 1.2, '', 0, maxWidth);
        
        const r = pdf.report;
        const text = r.job ? new OfferingActionPipe().transform(r.offeringType, true) : '';

        y += 19.564;
        
        pdf.text(`For ${r.offeringName} ${text} ${r.job}`, x, y, 'Raleway-Regular', 13);
        y += pdf.doc.getLineHeight();
        
        const lineMargin = 28.623; 
        y += lineMargin;
        pdf.line(45.853, y, 522, this.color, 1);
        
        return y + lineMargin;
    }
}