import {Injectable, signal} from '@angular/core';
import {
    BriefReportBase,
    DiscussionTopic,
    JobDescriptionBriefReport,
    ProblemBriefReport,
    ProcessBriefReport,
    SalesPrepReport, SalesPrepReportInfo,
    TrendBriefReport
} from "../../model";
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {catchError, map, Observable, retry, tap, throwError} from "rxjs";
import {OfferingActionPipe} from "../../pipes/offering-action.pipe";
import {MessageService} from "../message.service";
import {AuthService} from "../auth.service";

@Injectable({
    providedIn: 'root'
})
export class BriefReportsService {

    errorMessage = signal<string>('');

    constructor(private http: HttpClient, private messageService: MessageService, private authService: AuthService) {
    }

    loadProblemReport(title: DiscussionTopic, report: SalesPrepReportInfo): Observable<ProblemBriefReport | null> {
        return this._load<ProblemBriefReport>('problem', title, report);   
    }

    loadTrendReport(title: DiscussionTopic, report: SalesPrepReportInfo): Observable<TrendBriefReport | null> {
        return this._load<TrendBriefReport>('trend', title, report);
    }

    loadProcessReport(title: DiscussionTopic, report: SalesPrepReportInfo): Observable<ProcessBriefReport | null> {
        return this._load<ProcessBriefReport>('process', title, report);
    }
    
    loadJobDescriptionReport(title: DiscussionTopic, report: SalesPrepReportInfo): Observable<JobDescriptionBriefReport | null> {
        return this._load<JobDescriptionBriefReport>('job-description', title, report);
    }

    private _load<T extends BriefReportBase>(type: string, title: DiscussionTopic, report: SalesPrepReportInfo) {
        
        /*if (!this.authService.authenticated()) {
            this.messageService.warning('Oops! It looks like you\'re not logged in. Only registered users can generate reports. Please log in or sign up to access this feature.');
            return of(null);
        }*/

        let subject = report.offeringName;
        const job = report.job;        
        if (!!job && job != '_') {
            const action = new OfferingActionPipe().transform(report.offeringType, true)
            subject = `${report.offeringName} ${action} ${job}`;            
        }

        // let subject = report.subject;
        // subject = subject.startsWith('Use ') ? subject.substring(4) : subject;
        
        return this.http.post<T>('/api/brief-report/' + type, {
            title: title.title,
            description: title.description,
            subject
        }).pipe(
            retry(1),
            map(r => this._fixCitationsNumbers<T>(r)),
            tap(r => {
                r.title = title.title;
                r.description = title.description;
                r.offeringName = report.offeringName;
                r.offeringType = report.offeringType;
                r.job = report.job;                                
            }),            
            catchError(e => this._handleError(e))
        );
    }

    private _handleError(e: HttpErrorResponse) {
        console.error(e);
        
        if (!this.authService.authenticated() && e.error?.error == 'The Access Code is invalid') {
            this.messageService.warning('Oops! It looks like you\'re not logged in. Only registered users can generate reports. Please log in or sign up to access this feature.');
        } else {
            this.messageService.error('Unable to generate Brief Report. Please try again in 1-2 minutes');
        }
        
        this.errorMessage.set(e?.error || 'Brief Report Generation Failed.');
        return throwError(() => this.errorMessage());
    }

    private _validCitationsNumbers(r: BriefReportBase) {
        for (let i = 0; i < r.citations.length; i++) {
            const c = r.citations[i];
            if (+c.title != i + 1) {
                return false;
            }
        }
        return true;
    }
    private _fixCitationsNumbers<T extends BriefReportBase>(r: T) {
        if (this._validCitationsNumbers(r)) return r;
        
        console.log('[BriefReportService] cleanup numbers', r.citations);

        let json = JSON.stringify(r);
        
        for (let i = 0; i < r.citations.length; i++) {
            const c = r.citations[i];
            const index = i + 1;
            if (+c.title != index) {
                const regex = new RegExp(`\\[${c.title}\\]`, "g");
                json = json.replace(regex, `[${index}]`);
            }
        }
        
        const result = <T> JSON.parse(json);
        result.citations.forEach((c, i) => c.title = '' + (i + 1));
        return result;
    }
}
