import {BriefReportItem} from "../../model";
import {CallReportPdfGenerator} from "./call-report-pdf-generator";


export class CallReportPdfCheckboxSection {    
    private title = '';

    constructor(private pdf: CallReportPdfGenerator, private item: BriefReportItem) {
        this.title = item.title ? item.title + ': ' : '';
    }

    draw(y: number) {
        const pdf = this.pdf;

        const paddingX = 86.243;
        const checkboxSize = 10.558;
        const fontSize = 9;
        // const lineHeight = fontSize * 1.15; // Line height multiplier
        const maxWidth = 464.707; // this.pdf.styles.pageWidth;

        const doc = this.pdf.doc;

        doc.rect(68.093, y, checkboxSize, checkboxSize);
        
        doc.setFontSize(fontSize);
        doc.setFont("Raleway-ExtraBold");
        const boldTextWidth = pdf.text(this.title, paddingX, y);
        let text = this.item.description;
        if (!text) {
            return y + pdf.doc.getLineHeight();
        }
        
        doc.setFont("Raleway-Medium");
        const firstLineWidth = maxWidth - boldTextWidth;
        // Split the first part of the regular text that fits on the remaining space of the first line        
        const lines = doc.splitTextToSize(text, firstLineWidth);
        const firstLine: string = lines[0];
        const remainingText = text.substring(firstLine.length).trim();
        
        // Step 3: Now split the rest of the regular text to fit the full width of the page (for subsequent lines)        
        pdf.text(firstLine, paddingX + boldTextWidth, y);
        return pdf.text(remainingText, paddingX, y + doc.getLineHeight() * 1.2, '', 0, maxWidth);
    }

    getHeight() {
        const fontSize = 9;
        // const lineHeight = fontSize * 1.15; // Line height multiplier
        const pageWidth = this.pdf.pageWidth;
        
        const doc = this.pdf.doc;
        doc.setFontSize(fontSize);                
        doc.setFont("Raleway-ExtraBold");        
        const boldTextWidth = doc.getTextDimensions(this.title).w;
        
        let text = this.item.description;
        if (!text) {
            return doc.getLineHeight();
        }
        
        doc.setFont("Raleway-Medium");
        const firstLineWidth = pageWidth - boldTextWidth;
        // Split the first part of the regular text that fits on the remaining space of the first line
        
        const lines = doc.splitTextToSize(text, firstLineWidth);
        const firstLine: string = lines[0];
        const remainingText = text.substring(firstLine.length).trim();
        
        // Step 3: Now split the rest of the regular text to fit the full width of the page (for subsequent lines)
        const remainingRegularText = doc.splitTextToSize(remainingText, pageWidth);
        // Step 4: Calculate total height
        // Total lines = 1 line with bold and part of regular text + remaining regular text lines
        const totalLines = 1 + remainingRegularText.length;
        // Total height calculation based on line count
        const totalHeight = totalLines * (doc.getLineHeight() * 1.2);

        return totalHeight;
    }
}