import {
    ChangeDetectionStrategy, ChangeDetectorRef,
    Component, ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import {
    FormNavigationButtonsComponent
} from "../../../../components/form-navigation-buttons/form-navigation-buttons.component";
import {SpeakingAnimationComponent} from "../speaking-animation/speaking-animation.component";
import {RecordingAnimationComponent} from "../../../../components/recording-animation/recording-animation.component";
import {CallReportVoiceInputStepOptions} from "../../../../services/call-report-voice-input/model";

@Component({
    selector: 'app-voice-input-panel',
    standalone: true,
    imports: [
        FormNavigationButtonsComponent,
        SpeakingAnimationComponent,
        RecordingAnimationComponent
    ],
    templateUrl: './voice-input-panel.component.html',
    styleUrl: './voice-input-panel.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class VoiceInputPanelComponent implements OnChanges {
    @ViewChild('editableDiv') editableDiv: ElementRef<HTMLDivElement> | undefined;
    
    @Input() listening = false;
    @Input() speaking = false;
    @Input() text = ''
    @Input() input = ''
    @Input() options: CallReportVoiceInputStepOptions[] | undefined;

    @Output() onSelect = new EventEmitter<CallReportVoiceInputStepOptions>();
    @Output() onToggleListening = new EventEmitter();
    @Output() onPrev = new EventEmitter();
    @Output() onNext = new EventEmitter<string>();

    content = '';
    formattedText = '';
    nextTitle = '';
    selected : string | undefined;
    
    constructor(private cdref: ChangeDetectorRef) {
    }
    
    ngOnChanges() {        
        this.formattedText = this.text.replace(/\n/g, '<p>');
        this.textChange();
    }

    next() {
        let t = '';

        const el = this.editableDiv?.nativeElement;
        if (el) {
            t = el.innerText;
        }
        console.log('[VoiceInputPanelComponent] ', t);
        this.onNext.emit(t);
    }

    textChange() {
        if (this.text == 'Anything else we should include?') {
            this.nextTitle = 'Generate Call Report';
        } else {
            this.nextTitle = (this.editableDiv?.nativeElement?.innerText || '') ? 'Next' : 'Skip';
        }
        
        this.cdref.detectChanges();
    }
}
