<div class="header">
    <div class="sp-wrapped">
        <app-call-report-builder-header [compact]="true" [voiceInput]="voiceInput()" (voiceInputChange)="setInput($event)" qa="cl-build-head"/>
    </div>
</div>

<div class="sp-wrapped">
    @if (outcome) {

        @if (voiceInput()) {
            <app-voice-input-panel
                    [listening]="speechRecognitionService.recording()"
                    [input]="speechRecognitionService.transcript()"

                    [text]="callReportVoiceInputService.currentStepText()"
                    [options]="callReportVoiceInputService.currentStepOptions()"

                    [speaking]="speechSynthesisService.speaking()"

                    (onToggleListening)="speechRecognitionService.toggleRecording()"
                    (onSelect)="callReportVoiceInputService.select($event)"
                    (onPrev)="voicePrev()"
                    (onNext)="voiceNext($event)"
            />
        } @else {
            <app-outcome-form
                    [skipDisabled]="skipDisabled"
                    [index]="index"
                    [data]="outcome"
                    [(skip)]="skip"
                    (onPrev)="prev()"
                    (onNext)="next($event)" 
                    (onSkip)="skipStep()" qa="o-form"/>
        }
    }
</div>
