import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'ordinalLong',
    standalone: true
})
export class OrdinalLongPipe implements PipeTransform {

    private ordinalMap: { [key: number]: string } = {
        1: 'first',
        2: 'second',
        3: 'third',
        4: 'fourth',
        5: 'fifth',
        6: 'sixth',
        7: 'seventh',
        8: 'eighth',
        9: 'ninth',
        10: 'tenth',
        11: 'eleventh',
        12: 'twelfth',
        13: 'thirteenth',
        14: 'fourteenth',
        15: 'fifteenth',
        16: 'sixteenth',
        17: 'seventeenth',
        18: 'eighteenth',
        19: 'nineteenth',
        20: 'twentieth',
        30: 'thirtieth',
        40: 'fortieth',
        50: 'fiftieth',
        60: 'sixtieth',
        70: 'seventieth',
        80: 'eightieth',
        90: 'ninetieth'
    };

    private tensMap: { [key: number]: string } = {
        2: 'twenty',
        3: 'thirty',
        4: 'forty',
        5: 'fifty',
        6: 'sixty',
        7: 'seventy',
        8: 'eighty',
        9: 'ninety'
    };

    transform(value: number): string {
        if (value == null || isNaN(value)) {
            return '';
        }

        if (this.ordinalMap[value]) {
            return this.ordinalMap[value];
        } else if (value >= 21 && value <= 99) {
            const tens = Math.floor(value / 10);
            const ones = value % 10;

            const tensWord = this.tensMap[tens] || '';
            const onesOrdinal = this.ordinalMap[ones] || '';

            if (ones === 0) {
                return this.ordinalMap[tens * 10];
            } else {
                return `${tensWord}-${onesOrdinal}`;
            }
        } else {
            // Fallback for numbers outside the handled range
            return value.toString();
        }
    }

}
