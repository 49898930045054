import {PdfGenerator} from "../pdf-generator";
import {DiscussionTopicsSection, ILegendItem} from "./discussion-topics-section";
import {DiscussionTopics} from "../../../model";


export class CommonProblemsSection extends DiscussionTopicsSection {
    whatLegendItem = {
        color: '#F9E5D5',
        label: 'What',
        items: [
            'Can you describe this problem?',
            'When does it happen?',
            'Where?',
            'How often?'
        ]
    }
    
    whyLegendItem = {
        color: '#DEF3DC',
        label: 'Why',
        items: [
            'How does this impact you?',
            'Who does this impact?'
        ]
    }
    
    clarifyLegendItem = {
        color: '#DEEFFA',
        label: 'Clarify?',
        items: [
            'Are you saying you want to ... ?'
        ]
    }
    
    constructor(pdf: PdfGenerator, topics: DiscussionTopics) {
        super(pdf, topics.commonProblems.filter(x => x.selected), topics.role, 'COMMON PROBLEMS', 'PROBLEM', 'common-problems', 18, 18);
    }
    
    override drawTopLegend(y: number): number {
        return Math.max(
            this._drawLegendItem(44, y, this.whatLegendItem, true),
            this._drawLegendItem(224, y, this.whyLegendItem, true),
            this._drawLegendItem(404, y, this.clarifyLegendItem, true)
        );
    }

    override drawSideLegend(y: number, startPage: number, endPage: number) {
        if (startPage != endPage) {
            this.pdf.doc.setPage(startPage);
        }
        y = this._drawLegendItem(405, y, this.whatLegendItem);
        y = this._drawLegendItem(405, y, this.whyLegendItem);
        y = this._drawLegendItem(405, y, this.clarifyLegendItem);
        
        const currentPage = this.pdf.getPageNumber();
        if (currentPage < endPage) {
            this.pdf.doc.setPage(endPage);
            return 0; // no need to offset for the legend height
        }
        
        return y;
    }

    private _drawLegendItem(x: number, y: number, item: ILegendItem, topLegend = false) {
        const pdf = this.pdf;
        const radius = 10;
        
        const descMarginTop = 41.859;
        const descMarginBottom = 19.944;
        const sections = item.items.map(x => pdf.createSection(x, 135, 'Raleway-Bold', 9));
        const descItemMargin = 13.048;

        const h = sections.reduce((a, b) => a + b.getHeight(pdf) + descItemMargin, 0) 
            + descMarginTop + descMarginBottom - descItemMargin;
        
        if (!topLegend && y + h > pdf.pageHeight) {
            pdf.doc.setPage(pdf.doc.getCurrentPageInfo().pageNumber + 1);
            y = 60.577;
        }

        // const h = 87.620 + descHeight - pdf.doc.getLineHeight();
        pdf.doc.setFillColor(item.color);
        // 405.000
        pdf.doc.roundedRect(x, y, 162.000, h, radius, radius, 'F');

        x += 16;

        pdf.text(item.label, x, y + 18.377, 'Raleway-ExtraBoldItalic', 14);

        y += descMarginTop;
        
        sections.forEach(s => y = s.draw(pdf, x, y) + descItemMargin)

        return y + descMarginBottom + 5;
    }
}