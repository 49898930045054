import {PdfGenerator} from "../pdf-generator";
import {DiscussionTopicsSection, ILegendItem} from "./discussion-topics-section";
import {DiscussionTopic, DiscussionTopics} from "../../../model";

export class MarketTrendsSection extends DiscussionTopicsSection {
    listenLegendItem: ILegendItem = {
        color: '#F9D3D8',
        icon: 'listen',
        label: '1. LISTEN',
        items: ['For any problems they bring up'],
        height: 87.620
    }
    
    askLegendItem = {
        color: '#DEF3DC',
        icon: 'ask',
        label: '2. ASK',
        items: ['If this topic is giving them problems'],
        height: 100
    }
    
    suggestLegendItem = {
        color: '#DEEFFA',
        icon: 'suggest',
        label: '3. SUGGEST',
        items: ['Using Common Problems & Process Steps'],
        height: 100
    }
    
    constructor(pdf: PdfGenerator, topics: DiscussionTopics) {
        super(pdf, topics.marketTrends.filter(x => x.selected), topics.role, 'MARKET TRENDS', 'TREND', 'market-trends', 18, 10.8);
    }

    override drawTopLegend(y: number): number {
        return Math.max(
            this._drawLegendItem(44, y, this.listenLegendItem, true),
            this._drawLegendItem(224, y, this.askLegendItem, true),
            this._drawLegendItem(404, y, this.suggestLegendItem, true)
        );
    }

    override drawSideLegend(y: number, startPage: number, endPage: number) {
        if (startPage != endPage) {
            this.pdf.doc.setPage(startPage);
        }        
        y = this._drawLegendItem(405, y, this.listenLegendItem);
        y = this._drawLegendItem(405, y, this.askLegendItem);
        y = this._drawLegendItem(405, y, this.suggestLegendItem);

        const currentPage = this.pdf.getPageNumber();
        if (currentPage < endPage) {
            this.pdf.doc.setPage(endPage);
            return 0; // no need to offset for the legend height
        }
        return y;        
    }
    
    private _drawLegendItem(x: number, y: number, item: ILegendItem, topLegend = false) {
        const pdf = this.pdf;
        const radius = 10;
        const h = item.height!;
        
        if (!topLegend && y + h > pdf.pageHeight) {
            pdf.doc.setPage(pdf.doc.getCurrentPageInfo().pageNumber + 1);
            y = 60.577;
        }
        
        // const h = 87.620 + descHeight - pdf.doc.getLineHeight();
        pdf.doc.setFillColor(item.color);
        
        pdf.doc.roundedRect(x, y, 162.000, h, radius, radius, 'F');

        x += 81;
        
        pdf.doc.addImage(`assets/pdf/market-trends-${item.icon}.png`, 'png', x - 12.474, y + 15.287, 25, 25);
        
        pdf.text(item.label, x, y + 46.259, 'Raleway-ExtraBold', 14, 0, {
            baseline: 'top',
            align: 'center'
        });
        
        const descHeight = pdf.text(item.items[0], x, y + 64, 'Raleway-Regular', 9, 135, {
            baseline: 'top',
            align: 'center'
        });
        
        return y + h + 5;
    }
}