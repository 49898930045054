import {TextSection} from "../text-section";
import {PdfGenerator} from "../pdf-generator";
import {DiscussionTopic} from "../../../model";
import {IReportSection} from "../report-section";

export class DiscussionTopicSection implements IReportSection {

    maxWidth = 149.056;
    sectionMarginBottom = 21;
    titleMarginBottom = 5;


    private _height = 0;
    private _titleSection: TextSection;
    private _descSection: TextSection;
    private _briefLinkSection: TextSection;

    constructor(private pdf: PdfGenerator, private topic: DiscussionTopic, index: number, private briefTitle: string) {
        this._titleSection = pdf.createSection(`${index}. ${topic.title}`, this.maxWidth, 'Raleway-Bold', 10)
        this._descSection = pdf.createSection(topic.description, this.maxWidth, 'Raleway-Regular', 9)
        this._briefLinkSection = pdf.createSection(briefTitle, this.maxWidth, 'Raleway-Regular', 9)
    }

    getHeight() {
        if (this._height) {
            return this._height;
        }
        this._height = this._titleSection.getHeight(this.pdf) + this.titleMarginBottom
            + this._descSection.getHeight(this.pdf) + this.titleMarginBottom
            + this._briefLinkSection.getHeight(this.pdf)            
            + this.sectionMarginBottom;
        return this._height;
    }

    draw(x: number, y: number) {
        const pdf = this.pdf;
        
        y = this._titleSection.draw(pdf, x, y) + this.titleMarginBottom;
        y = this._descSection.draw(pdf, x, y) + this.titleMarginBottom;

        const doc = pdf.doc;

        const { title, description } = this.topic;
        const { id, offeringName, offeringType, job } = pdf.report;
        
        const url = `${location.origin}/brief/${this.briefTitle.toLowerCase()}/?` +
            new URLSearchParams({ id, title, description, offeringName, offeringType: '' + offeringType, job });
        
        doc.setTextColor(pdf.styles.linkColor);
        doc.textWithLink(this.briefTitle + ' BRIEF', x, y, { baseline: 'top', url: url.toString() });
        doc.setTextColor(pdf.styles.textColor);
        
        return y + doc.getLineHeight() + this.sectionMarginBottom;
    }

}