import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {SpinnerComponent} from "../../../../components/spinner/spinner.component";

@Component({
    selector: 'app-report-generation-indicator',
    standalone: true,
    imports: [
        SpinnerComponent
    ],
    templateUrl: './report-generation-indicator.component.html',
    styleUrl: './report-generation-indicator.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReportGenerationIndicatorComponent {
    @Input() done = false;
    @Input() title = '';
    @Input() description = 'This process typically takes about 20 seconds.';

}
