import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    HostBinding,
    Input,
    Output
} from '@angular/core';

@Component({
    selector: 'app-input-mode-toggle',
    standalone: true,
    imports: [],
    templateUrl: './input-mode-toggle.component.html',
    styleUrl: './input-mode-toggle.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class InputModeToggleComponent {
    
    @HostBinding('class.compact')
    @Input() compact = false;

    @HostBinding('class.voice-mode') 
    @Input() voiceInput = false;
    
    @Output() voiceInputChange = new EventEmitter<boolean>();
    
    set(voiceInput: boolean) {
        if (this.voiceInput != voiceInput) {
            this.voiceInput = voiceInput;
            this.voiceInputChange.emit(voiceInput);
        }
    }
}
