import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

@Component({
    selector: 'app-tutor-avatar',
    standalone: true,
    imports: [],
    templateUrl: './tutor-avatar.component.html',
    styleUrl: './tutor-avatar.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TutorAvatarComponent {
    
}

