<app-main-header/>

<app-main-panel ngSkipHydration class="sp-wrapped">
    @if (loadingProfile()) {
        <app-spinner/>
    } @else {
        <app-pure-login
                (onLogin)="login($event)" 
                (signInWithGoogle)="signInWithGoogle()"
                (onForgotPassword)="forgotPassword()"
                (onCreateAccount)="createAccount()"
                [errorMessage]="errorMessage()"/>
    }
</app-main-panel>

<app-footer-compact/>