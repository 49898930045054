import {ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, OnChanges, Output} from '@angular/core';
import {CallReportRecodedOutcome} from "../../../../model";
import {FormsModule, NgForm, ReactiveFormsModule} from "@angular/forms";
import {AutosizeDirective} from "../../../../directives/autosize.directive";
import {
    FormNavigationButtonsComponent
} from "../../../../components/form-navigation-buttons/form-navigation-buttons.component";
import {AutofocusDirective} from "../../../../directives/autofocus.directive";
import {SpinnerComponent} from "../../../../components/spinner/spinner.component";

@Component({
    selector: 'app-text-form',
    standalone: true,
    imports: [
        AutosizeDirective,
        ReactiveFormsModule,
        FormsModule,
        FormNavigationButtonsComponent,
        AutofocusDirective,
        SpinnerComponent
    ],
    templateUrl: './text-form.component.html',
    styleUrl: './text-form.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextFormComponent implements OnChanges {
    title = '';
    
    @Input() generatingReport = false;
    @Input() step = 1;
    @Input() text = '';
    @Output() textChange = new EventEmitter<string>();
    
    @Output() onPrev = new EventEmitter();
    @Output() onNext = new EventEmitter<string>();

    @HostBinding('class.sp-form') f = true;
    @HostBinding('class.sp-form-alternative') a = true;

    ngOnChanges() {
        this.title = this._getTitle();        
    }
    
    next(f: NgForm) {
        if (f.valid) {
            this.onNext.emit(this.text);
        }
    }

    private _getTitle() {
        switch (this.step) {
            case 1:
                return '3a. Any other insights… job changes, initiatives, trends, competitive intel?';
            case 2:
                return '3b. Any opportunities to pursue… other than the outcomes entered earlier?';
            case 3:
                return '3c. What are the next steps for you or others?';
            case 4:
                return '3d. Anything else we should include?';
            default:
                return 'NA';
                
        }
    }
}
