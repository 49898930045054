import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input, OnChanges,
    OnInit,
    Output
} from '@angular/core';
import {Roles} from "../../../../model";
import {IVoiceSelection} from "../../../../services/speech-synthesis.service";

@Component({
    selector: 'app-text-to-speech-settings',
    standalone: true,
    imports: [],
    templateUrl: './text-to-speech-settings.component.html',
    styleUrl: './text-to-speech-settings.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextToSpeechSettingsComponent implements OnChanges {
    @Input() playingVoice = '';
    @Input() voices: SpeechSynthesisVoice[] = [];
    @Input() selectedVoices: IVoiceSelection[] = [];
    @Input() selectedRole = Roles.Coach;
    
    selectedVoice = '';
    
    roles = [
        Roles.Coach,
        Roles.Customer,
        Roles.User
    ]

    Roles = Roles;
    
    coachVoice = '';
    customerVoice = '';
    userVoice = '';
    
    @Output() onReset = new EventEmitter();
    @Output() onPlayVoice = new EventEmitter<SpeechSynthesisVoice>();
    @Output() onSelectVoice = new EventEmitter<IVoiceSelection>();

    constructor(private cdRef: ChangeDetectorRef) {        
    }
    
    ngOnChanges() {
        this.updateSelection();
    }

    updateSelection() {        
        this.selectedVoice = this._getVoice(this.selectedRole);

        this.coachVoice = this._getVoice(Roles.Coach); 
        this.customerVoice = this._getVoice(Roles.Customer); 
        this.userVoice = this._getVoice(Roles.User) ;
        
        this.cdRef.detectChanges();
    }
    
    private _getVoice(role: Roles) {
        return this.selectedVoices.find(x => x.role == role)?.voice || '';
    }

    select(e: MouseEvent, voice: SpeechSynthesisVoice, role: Roles) {
        e.stopPropagation();
        e.preventDefault();
        this.onSelectVoice.emit({voice: voice.voiceURI, role});
    }
    
    getPrefix(selected: boolean) {
        return selected ? 'Has been configured as ' : 'Set as ';
    }
}
