import {Component} from '@angular/core';
import {
    CallReportBuilderHeaderComponent
} from "../../components/call-report-builder-header/call-report-builder-header.component";
import {MeetingOverviewFormComponent} from "../../components/meeting-overview-form/meeting-overview-form.component";
import {ActivatedRoute, Router} from "@angular/router";
import {CallReportBuilderService} from "../../../../services/call-report-builder.service";
import {VoiceInputPanelComponent} from "../../components/voice-input-panel/voice-input-panel.component";
import {SpeechSynthesisService} from "../../../../services/speech-synthesis.service";
import {SpeechRecognitionService} from "../../../../services/speech-recognition.service";
import {
    CallReportVoiceInputService
} from "../../../../services/call-report-voice-input/call-report-voice-input.service";
import {CallReportVoiceInputSteps} from "../../../../services/call-report-voice-input/call-report-voice-input-steps";

@Component({
    selector: 'app-meeting-overview-screen',
    standalone: true,
    imports: [
        CallReportBuilderHeaderComponent,
        MeetingOverviewFormComponent,
        VoiceInputPanelComponent
    ],
    templateUrl: './meeting-overview-screen.component.html',
    styleUrl: './meeting-overview-screen.component.scss'
})
export class MeetingOverviewScreenComponent {

    inputData = this.reportBuilder.inputData;
    voiceInput = this.reportBuilder.voiceInput;
    currentStep = this.callReportVoiceInputService.currentStep;
    currentStepText = this.callReportVoiceInputService.currentStepText;
    currentStepOptions = this.callReportVoiceInputService.currentStepOptions;

    
    constructor(private router: Router,
                private route: ActivatedRoute,
                private reportBuilder: CallReportBuilderService,
                protected speechSynthesisService: SpeechSynthesisService,
                protected speechRecognitionService: SpeechRecognitionService,
                public callReportVoiceInputService: CallReportVoiceInputService
                ) {
    }

    prev() {
        window.history.back();
    }
    
    next() {
        this.reportBuilder.saveInputData(2);
        this.router.navigate(['../outcomes', 1], { relativeTo: this.route });
    }

    setInput(voiceInput: boolean) {
        this.callReportVoiceInputService.setMeetingOverviewStep();
        setTimeout(() => this.reportBuilder.setVoiceInput(voiceInput), 500);
    }

    voiceNext(text: string) {
        this.callReportVoiceInputService.next(text);        
        
        if (this.currentStep().step == CallReportVoiceInputSteps.OutcomesCount) {
            this.next();
        }
    }
}
