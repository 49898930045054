import {Injectable} from '@angular/core';
import {jsPDF} from "jspdf";
import {JobDescriptionBriefReport, ProcessBriefReport, SalesPrepReport, TrendBriefReport} from "../model";
import {MessageService} from "./message.service";
import { HttpClient } from "@angular/common/http";
import {DatePipe} from "@angular/common";
import {PdfGenerator} from "./pdf-export";
import {AuthService} from "./auth.service";
import {ProblemBriefReport} from "../model/problemBriefReport";
import {MyProblemBriefReportGenerator} from "./brief-reports";
import {BriefSummarySection} from "./brief-reports/brief-summary-section";
import {BriefCheckboxesSection} from "./brief-reports/brief-checkboxes-section";
import {BriefDisclaimerSection} from "./brief-reports/brief-disclaimer-section";
import {ProcessBriefCheckboxesSection} from "./brief-reports/process-brief-checkboxes-section";
import {BriefCitationsSection} from "./brief-reports/brief-citations-section";
import {CustomerHandoutArgs, CustomerHandoutsGenerator} from "./customer-handouts/customer-handouts-generator";
import {CallReportPdfGenerator} from "./call-report/call-report-pdf-generator";
import {CallReportPdfSummarySection} from "./call-report/call-report-pdf-summary-section";
import {CallReportPdfCheckboxSection} from "./call-report/call-report-pdf-checkbox-section";
import {CallReportPdfCheckboxesSection} from "./call-report/call-report-pdf-checkboxes-section";

@Injectable({
    providedIn: 'root'
})
export class PdfExportService {

    private static _fonts = new Map<string, string>();
    
    constructor(private messageService: MessageService, 
                private authService: AuthService,
                private http: HttpClient) {
    }
    
    async export(report: SalesPrepReport) {
        console.log('[PdfExportService] export', report);

        const name = report.company.name.replace(/[^a-z0-9 ]/gi, '');
        const date = new DatePipe('en-US').transform(new Date());
        const profile = this.authService.userProfile();
        // if (!profile) throw Error('Profile not loaded');
        const generator = new PdfGenerator(report, profile);
        await this._loadFonts(generator.doc, PdfGenerator.fonts);
        await generator.generate(`SalesPrep Report for ${name} - ${date}.pdf`);
        this._showMessage();
    }
    
    async exportProblemBriefReport(report: ProblemBriefReport) {
        console.log('[PdfExportService] exportProblemBriefReport', report);

        const profile = this.authService.userProfile();
        // if (!profile) throw Error('Profile not loaded');
        const generator = new MyProblemBriefReportGenerator(report, profile);
        await this._loadFonts(generator.doc, MyProblemBriefReportGenerator.fonts);

        const r = report;
        
        /*r.relevantMetrics.forEach(metric => {
            if (!metric.description) {
                metric.description = "These scores can be affected by the aesthetic appeal of the packaging. Lower scores might indicate that customers are not impressed with the packaging, leading to potential brand loyalty issues.";
            }
            
            if (!metric.subtopics.length) {
                metric.subtopics.push({
                    title: 'Unit',
                    description: 'Percentage or ratio'
                });
                metric.subtopics.push({
                    title: 'Typical Current Value',
                    description: 'Varies, but a 5-15% increase could be compelling.'
                });
            }
            
        });*/
        
        await generator.generate(
            report.title, 'Problem Brief', 'f47415', '1', 172.553, 180.564, [
                new BriefSummarySection(generator, 'Problem Summary', r.summary),
                new BriefCheckboxesSection(generator, 'Causes and Conditions of Problem', r.causesAndConditionsOfProblem),
                new ProcessBriefCheckboxesSection(generator, 'Relevant Metrics', r.relevantMetrics),
                new BriefCheckboxesSection(generator, 'Impact of Solving Problem', r.impactOfSolvingProblem),
                new BriefDisclaimerSection(generator),
                new BriefCitationsSection(generator, r.citations)
            ]            
        );
        
        this._showMessage();
    }

    async exportTrendBriefReport(report: TrendBriefReport) {
        console.log('[PdfExportService] exportTrendBriefReport', report);

        const profile = this.authService.userProfile();
        // if (!profile) throw Error('Profile not loaded');
        const generator = new MyProblemBriefReportGenerator(report, profile);
        await this._loadFonts(generator.doc, MyProblemBriefReportGenerator.fonts);

        const r = report;

        await generator.generate(
            report.title, 'Trend Brief', '70c72d', '2', 166.721, 109.724, [
                new BriefSummarySection(generator, 'Trend Summary', r.summary),
                new BriefCheckboxesSection(generator, 'Negative Implications', r.negativeImplications),
                new BriefCheckboxesSection(generator, 'Positive Implications', r.positiveImplications),
                new BriefCheckboxesSection(generator, 'Recommendations', r.recommendations),
                new BriefDisclaimerSection(generator),
                new BriefCitationsSection(generator, r.citations)
            ], 39.893
        );

        this._showMessage();
    }

    async exportJobDescriptionBriefReport(report: JobDescriptionBriefReport) {
        console.log('[PdfExportService] exportJobDescriptionBriefReport', report);

        const profile = this.authService.userProfile();
        // if (!profile) throw Error('Profile not loaded');
        const generator = new MyProblemBriefReportGenerator(report, profile);
        await this._loadFonts(generator.doc, MyProblemBriefReportGenerator.fonts);

        const r = report;

        await generator.generate(
            report.title, 'Job Description', '70c72d', '2', 166.721, 109.724, [
                new BriefSummarySection(generator, 'Role Overview', r.summary),
                new ProcessBriefCheckboxesSection(generator, 'Key Responsibilities', r.keyResponsibilities),
                new ProcessBriefCheckboxesSection(generator, 'Required Qualifications', r.requiredQualifications),
                new ProcessBriefCheckboxesSection(generator, 'Desired Skills', r.desiredSkills),
                new ProcessBriefCheckboxesSection(generator, 'Key Challenges', r.keyChallenges),
                new ProcessBriefCheckboxesSection(generator, 'Performance Metrics', r.performanceMetrics),
                new BriefDisclaimerSection(generator),
                new BriefCitationsSection(generator, r.citations)
            ], 39.893
        );

        this._showMessage();
    }

    async exportCallReport(report: SalesPrepReport) {
        console.log('[PdfExportService] exportJobDescriptionBriefReport', report);

        const profile = this.authService.userProfile();
        // if (!profile) throw Error('Profile not loaded');
        const generator = new CallReportPdfGenerator(report, profile);
        await this._loadFonts(generator.doc, MyProblemBriefReportGenerator.fonts);

        const r = report.callReport;
        if (!r) throw Error('Error is empty');

        await generator.generate(
            r.customerCompany, 'Customer Meeting Report', '70c72d', '2', 166.721, 109.724, [
                new CallReportPdfSummarySection(generator, 'Meeting Overview', r.meetingOverview),
                new CallReportPdfCheckboxesSection(generator, 'Desired Customer Outcomes', r.outcomes),
                new CallReportPdfSummarySection(generator, 'Pre Call Research', r.preCallResearch),
                new CallReportPdfSummarySection(generator, 'Other Insights', r.otherInsights),
                new CallReportPdfSummarySection(generator, 'Opportunities', r.opportunities),
                new CallReportPdfCheckboxesSection(generator, 'Next Steps', r.nextSteps.map(x => ({
                    title: '',    
                    description: x
                }))),                
                // new BriefDisclaimerSection(generator)
            ], 39.893
        );

        this._showMessage();
    }
    
    async exportCustomerHandouts(report: SalesPrepReport, arg: CustomerHandoutArgs) {
        console.log('[PdfExportService] exportCustomerHandouts', report);

        const profile = this.authService.userProfile();
        const generator = new CustomerHandoutsGenerator(report, profile);
        await this._loadFonts(generator.doc, CustomerHandoutsGenerator.fonts);
        await generator.generateAll(arg);
        this._showMessage();
    }
    
    async exportProcessBriefReport(report: ProcessBriefReport) {
        console.log('[PdfExportService] exportProcessBriefReport', report);

        const profile = this.authService.userProfile();
        // if (!profile) throw Error('Profile not loaded');
        const generator = new MyProblemBriefReportGenerator(report, profile);
        await this._loadFonts(generator.doc, MyProblemBriefReportGenerator.fonts);

        const r = report;

        await generator.generate(
            report.title, 'Process Brief', '9845a8', '3', 190.041, 188.506, [
                new BriefSummarySection(generator, 'Process Step Summary', r.summary),
                new ProcessBriefCheckboxesSection(generator, 'Detailed Analysis', r.detailedAnalysis),
                new ProcessBriefCheckboxesSection(generator, 'Improvement Ideas (Faster, Better, Cheaper)', r.improvementIdeas),
                /*new BriefCheckboxesSection(generator, 'Improvement Ideas (Faster, Better, Cheaper)', r.improvementIdeas.map(x => {
                    const args = x.title.split(': ', 2);
                    return {
                        title: args[0],
                        description: args.length > 1 ? args[1] : ''
                    }
                })),*/
                new BriefDisclaimerSection(generator),
                new BriefCitationsSection(generator, r.citations)
            ]
        );
        
        this._showMessage();
    }

    private async _loadFonts(doc: jsPDF, fonts: string[]) {
        try {
            for (let i = 0; i < fonts.length; i++) {
                const f = fonts[i];
                await this._loadFont(doc, f);
            }            
            doc.setFont(PdfGenerator.fonts[0]);
        } catch (e: any) {
            console.error(e);
            this.messageService.error('Unable to download font. PDF will be generated using font available offline.');
            doc.setFont('helvetica');
        }
    }

    private async _loadFont(pdf: any, filename: string, style: string = 'normal', name: string = ''): Promise<void> {
        const fonts = PdfExportService._fonts;
        let base64 = fonts.get(filename);
        if (!base64) {
            base64 = await this._download(filename);
            fonts.set(filename, base64);
        }
        pdf.addFileToVFS(filename, base64);
        pdf.addFont(filename, name ? name : filename, style);
    }

    private async _download(name: string): Promise<string> {
        const font = await this.http.get('./assets/fonts/' + name + '.ttf', {
            responseType: 'blob',
            observe: 'response'
        }).toPromise();

        return new Promise<string>((_resolve, reject) => {
            if (!font?.body) {
                reject('body is empty');
            } else {
                const reader = new FileReader();
                reader.onloadend = function() {
                    if (!reader.result) {
                        reject('result is empty');
                    } else {
                        let base64 = <string> reader.result;
                        base64 = base64.substr(base64.indexOf(',') + 1);
                        _resolve(base64);
                    }
                }
                reader.readAsDataURL(font.body);
            }
        });
    }
    
    private _showMessage() {
        this.messageService.success(`Export completed successfully`);
    }
}
