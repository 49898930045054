import {Component, Input} from '@angular/core';
import {Company} from "../../../../model";
import {Offering} from "../../../../model/offering";
import {ReportBuilderHeaderComponent} from "../report-builder-header/report-builder-header.component";
import {RouterLink} from "@angular/router";

@Component({
    selector: 'app-side-progress-bar',
    standalone: true,
    imports: [
        ReportBuilderHeaderComponent,
        RouterLink
    ],
    templateUrl: './side-progress-bar.component.html',
    styleUrl: './side-progress-bar.component.scss'
})
export class SideProgressBarComponent {
    @Input() step = 1;
    @Input() completedSteps = 1;
    @Input() company: Company | undefined;
    @Input() offering: Offering | undefined;
    @Input() job: string | undefined;
    
    @Input() callReportMode = false
    @Input() meetingDate: Date | undefined;
    @Input() meetingTime: string | undefined;
}
