<div class="sp-checkbox" title="Enable this option to have chat messages automatically read aloud." (click)="onToggle.emit()">

    <div class="read-aloud-toggle">
        <span class="material-symbols-outlined icon" qa="ic">
            @if (speechEnabled) {text_to_speech} @else {volume_off}
        </span>
        <div class="message" qa="msg">
            @if (speechEnabled) {
                Chat messages are being read aloud
            } @else {
                Chat messages are not being read aloud
            }
        </div>
        
        
    </div>

</div>

@if (speechEnabled) {
    <a class="settings" title="Click to configure voices" (click)="onSettings.emit()">
        <span class="material-symbols-outlined icon" qa="set">settings</span>
    </a>
}