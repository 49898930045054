export enum CallReportVoiceInputSteps {
    Start,
    CustomerLocation,
    MeetingType,
    ScopeReview,
    ScopeUpdate,
    
    Interviewers,
    InterviewersNames,
    
    // IntervieweesCount,
    IntervieweesStep,
    
    ReviewMeetingOverviewQuestion,
    ReviewMeetingOverview,
    
    OutcomesCount,
    OutcomeWhat,
    OutcomeWhy,
    OutcomeClarify,    
    
    Insights,
    Opportunities,
    NextSteps,
    Notes,
}