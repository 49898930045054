<div class="flex links">
    <a routerLink="/terms">Terms</a>
    <a routerLink="/privacy">Privacy</a>
    <a routerLink="/cookie-policy">Cookie Policy</a>
    <a routerLink="/faq">FAQs</a>    
    <a routerLink="/contact-us">Contact</a>
</div>
<div class="copy">
    Sales<span class="italic">Prep</span><span class="tm">TM</span> &nbsp;© 2025&nbsp;
    <a href="https://theaiminstitute.com" title="The AIM Institute - New Product Blueprinting and Everyday VOC">The AIM Institute</a>. 
    All Rights Reserved.
</div>