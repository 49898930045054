import {Component, Inject, OnInit, PLATFORM_ID, signal} from '@angular/core';
import {MainHeaderComponent} from "../../components/main-header/main-header.component";
import {
    PureBriefReportDownloadComponent
} from "./components/pure-brief-report-download/pure-brief-report-download.component";
import {FooterSmartComponent} from "../../components/footer-smart/footer-smart.component";
import {ActivatedRoute} from "@angular/router";
import {DiscussionTopic, OfferingType, SalesPrepReport, SalesPrepReportInfo} from "../../model";
import {BriefReportsService} from "../../services/brief-reports/brief-reports.service";
import {PdfExportService} from "../../services/pdfExport.service";
import {MessageService} from "../../services/message.service";
import {isPlatformBrowser} from "@angular/common";

@Component({
    selector: 'app-brief-report-download',
    standalone: true,
    imports: [
        MainHeaderComponent,
        PureBriefReportDownloadComponent,
        FooterSmartComponent
    ],
    templateUrl: './brief-report-download.component.html',
    styleUrl: './brief-report-download.component.scss'
})
export class BriefReportDownloadComponent implements OnInit {

    title = signal('Brief');
    generating = signal(true);
    
    constructor(private route: ActivatedRoute,
                private briefReportsService: BriefReportsService,
                private pdfExportService: PdfExportService,
                @Inject(PLATFORM_ID) private platformId: Object,
                private messageService: MessageService,
    ) {
    }

    ngOnInit() {
        if (!isPlatformBrowser(this.platformId)) {
            return;
        }
            
        const s = this.route.snapshot;
        const p = s.queryParams;

        const type = s.params['type'];
        const id = p['id'];
        const offeringName = p['offeringName'];
        const offeringType: OfferingType = +p['offeringType'];
        const job = p['job'];
        const title = p['title'];
        const description = p['description'];

        const topic = <DiscussionTopic> {
            title, description
        }
        
        const reportInfo = <SalesPrepReportInfo> {
            offeringName,
            offeringType,
            job,
        }

        this.title.set('Brief');
        
        switch (type) {
            case 'job':
                this.title.set('Job Description');
                this.jobDescriptionReport(topic, reportInfo);
                break;
                    
            case 'trend':
                this.trendsBriefReport(topic, reportInfo);
                break;
                
            case 'problem':
                this.problemsBriefReport(topic, reportInfo);
                break;
                
            case 'process':
                this.processBriefReport(topic, reportInfo);
                break;
                
            default:
                console.error(`[BriefReportDownloadComponent] Unknown type '${type}'`);
        }
    }
    
    jobDescriptionReport(stakeholder: DiscussionTopic, report: SalesPrepReportInfo) {
        console.log('[ReportViewerComponent] jobDescriptionReport', stakeholder);

        this.briefReportsService.loadJobDescriptionReport(stakeholder, report)
            .subscribe({
                next: async (report) => {
                    if (report) await this.pdfExportService.exportJobDescriptionBriefReport(report);
                },
                error: () => this._refreshUI(stakeholder),
                complete: () => this._refreshUI(stakeholder),
            });
    }
    
    trendsBriefReport(topic: DiscussionTopic, report: SalesPrepReportInfo) {
        console.log('[BriefReportDownloadComponent] trendsBriefReport(topic)', topic);
        this.briefReportsService.loadTrendReport(topic, report)
            .subscribe({
                next: async (report) => {
                    if (report) await this.pdfExportService.exportTrendBriefReport(report);
                },
                error: () => this._refreshUI(topic),
                complete: () => this._refreshUI(topic),
            });
    }

    problemsBriefReport(topic: DiscussionTopic, report: SalesPrepReportInfo) {
        console.log('[BriefReportDownloadComponent] problemsBriefReport(topic)', topic);
        this.briefReportsService.loadProblemReport(topic, report)
            .subscribe({
                next: async (report) => {
                    if (report) await this.pdfExportService.exportProblemBriefReport(report);
                },
                error: () => this._refreshUI(topic),
                complete: () => this._refreshUI(topic),
            });
    }

    processBriefReport(topic: DiscussionTopic, report: SalesPrepReportInfo) {
        console.log('[BriefReportDownloadComponent] processBriefReport(topic)', topic);
        this.briefReportsService.loadProcessReport(topic, report)
            .subscribe({
                next: async (report) => {
                    if (report) await this.pdfExportService.exportProcessBriefReport(report);
                },
                error: () => this._refreshUI(topic),
                complete: () => this._refreshUI(topic),
            });
    }

    private _refreshUI(topic: DiscussionTopic) {
        this.generating.set(false);
    }
}
