import {ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output} from '@angular/core';

@Component({
    selector: 'app-text-to-speech-toggle',
    standalone: true,
    imports: [],
    templateUrl: './text-to-speech-toggle.component.html',
    styleUrl: './text-to-speech-toggle.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextToSpeechToggleComponent {
    
    @Input() speechEnabled = false;
    
    @HostBinding('class.not-supported') @Input() notSupported = false;   
    
    @Output() onToggle = new EventEmitter();
    @Output() onSettings = new EventEmitter();

}
