import {Injectable, signal} from '@angular/core';
import {MessageService} from "./message.service";
import {SpeechSynthesisService} from "./speech-synthesis.service";

@Injectable({
    providedIn: 'root'
})
export class SpeechRecognitionService {

    enabled = signal(false);
    recording = signal(false);

    transcript = signal('');
    transcriptCompleted = signal('');

    private _recognition: any;

    private _silenceTimer: any;
    private SILENCE_TIMEOUT_MS = 2000;
    
    constructor(private messageService: MessageService, private speechSynthesisService: SpeechSynthesisService) {
        const w = <any>window;
        const SpeechRecognition = w.SpeechRecognition || w.webkitSpeechRecognition;
        
        if (!SpeechRecognition) {
            console.error("Web Speech API is not supported in this browser.");
            return;                
        }
        
        const recognition = new SpeechRecognition();
        recognition.lang = 'en-US';
        recognition.continuous = true;
        recognition.interimResults = true;

        recognition.onstart = (e: any) => {            
            this.speechSynthesisService.stop();
            console.log('onstart...');
        };
        
        recognition.onend = (e: any) => {
            this.recording.set(false);
            this.transcriptCompleted.set(this.transcript());
            console.log('[SpeechService] recognition on end');
        };

        recognition.onresult = (event: any) => {
            clearTimeout(this._silenceTimer);
            
            let transcript = '';
            
            // console.log("Transcribed text:", transcript);
            
            for (var i = 0; i < event.results.length; ++i) {
                transcript += event.results[i][0].transcript;
                console.log("Interim transcript:", transcript);
                
                if (event.results[i].isFinal) {
                }
            }

            console.log('[SpeechService] recognition on result: ', transcript);
            this.transcript.set(transcript);

            this._silenceTimer = setTimeout(() => {
                recognition.stop();
            }, this.SILENCE_TIMEOUT_MS);
        };

        recognition.onerror = (event: any) => {
            if (event.error == "aborted") {
                return;
            } else if (event.error == "no-speech") {
                this.messageService.warning('Sorry, we cannot hear you.');
            } else {
                this._onNotSupported();
                console.error("Error occurred in speech recognition:", event.error);
            }
        };
        
        this._recognition = recognition;
        this.enabled.set(true);
    }
    
    static warningShown = false;
    private _onNotSupported() {
        if (!SpeechRecognitionService.warningShown) {
            this.messageService.warning('Speech-to-Text functionality is currently working only in Edge, Chrome and Safari browsers');
            SpeechRecognitionService.warningShown = true;
        }        
    }

    start() {
        console.log('[SpeechRecognitionService] start');
        if (!this.enabled()) {
            this._onNotSupported();            
            return;
        }
        if (!this.recording()) {
            this.recording.set(true);
            this._recognition.start();
        }
    }
    
    stop() {
        if (this.recording()) {
            this.recording.set(false);
            this._recognition?.stop();
        }
    }
    
    toggleRecording() {
        if (this.recording()) {
            this.stop();
        } else {
            this.start();
        }
    }
}
