import {Component, computed, HostBinding, Input, OnInit, signal} from '@angular/core';
import {MainHeaderComponent} from "../../components/main-header/main-header.component";
import {MainPanelComponent} from "../../components/main-panel/main-panel.component";
import {AuthService} from "../../services/auth.service";
import {InitialsPipe} from "../../pipes/initials.pipe";
import {ActivatedRoute, RouterLink} from "@angular/router";
import {MessageService} from "../../services/message.service";
import {DatePipe} from "@angular/common";
import {FooterCompactComponent} from "../../components/footer-compact/footer-compact.component";
import {PureProfileComponent} from "./components/pure-profile/pure-profile.component";
import {InfoPanelComponent} from "../../components/info-panel/info-panel.component";
import {Offering, OfferingCategory, OfferingType, TutorCoachingLevels} from "../../model";
import {ProductsService} from "../../services/products.service";
import {catchError, retry, throwError} from "rxjs";
import {PureAddOfferingComponent, RemoveJobArgs} from "./components/pure-add-offering/pure-add-offering.component";
import {MainTitleComponent} from "../../components/main-title/main-title.component";

@Component({
    selector: 'app-profile',
    standalone: true,
    imports: [
        MainHeaderComponent,
        MainPanelComponent,
        InitialsPipe,
        RouterLink,
        DatePipe,
        FooterCompactComponent,
        PureProfileComponent,
        InfoPanelComponent,
        PureAddOfferingComponent,
        MainTitleComponent
    ],
    templateUrl: './profile.component.html',
    styleUrl: './profile.component.scss'
})
export class ProfileComponent implements OnInit {
    @HostBinding('class.sp-screen') s = true;

    userProfile = this.authService.userProfile;
    offerings = this.authService.offerings;
    setupOfferings = signal(false);
    addOffering = signal(false);
    selectedOffering = signal<Offering | undefined>(undefined);

    type = signal<OfferingType | undefined>(undefined);
    category = signal<OfferingCategory | undefined>(undefined);

    categories = computed<OfferingCategory[]>(() => {
        if (this.type()) {
            return this.productsService.offeringTypes().find(x => x.id == this.type())?.categories || [];
        }
        return [];
    });
    
    constructor(private route: ActivatedRoute, 
                public authService: AuthService, 
                public productsService: ProductsService,
                public messageService: MessageService) {
    }
    
    ngOnInit() {
        const snapshot = this.route.snapshot;
        this.setupOfferings.set(!!snapshot.data['setupOfferings']);
    }

    cancelDialog() {
        this.addOffering.set(false);
        this.selectedOffering.set(undefined);        
        this.type.set(undefined);
        this.category.set(undefined);
    }

    addOfferingMode() {
        this.selectedOffering.set(undefined);
        this.addOffering.set(true);
    }

    saveOffering(offering: Offering) {
        console.log('[ProfileComponent] saveOffering ', offering);
        this.productsService.addOffering(offering)
            .subscribe(() => {
                console.log('[AddOfferingComponent] saveOffering success');
                this.cancelDialog();
            });
    }
    

    removeJob(args: RemoveJobArgs) {
        console.log('[ProfileComponent] removeJob ', args);
        const { offering: o, jobIndex: index } = args;

        if (o.jobs[index]) {
            this.messageService.confirm('You are about to remove job. Are you sure you want to proceed?',
                () => o.jobs = o.jobs.filter((x, i) => i != index));
        } else {
            o.jobs = o.jobs.filter((x, i) => i != index);
        }
        
    }

    removeOffering(offering: Offering) {
        this.messageService.confirm('You are about to remove offering. Are you sure you want to proceed?',
            () => this._removeOffering(offering));
    }

    private _removeOffering(offering: Offering) {
        console.log('[AddOfferingComponent] removeOffering ', offering);
        this.productsService.removeOffering(offering)
            .subscribe(() => {
                console.log('[AddOfferingComponent] removeOffering success');
                this.cancelDialog();
            });
    }

    protected readonly TutorCoachingLevels = TutorCoachingLevels;
}
