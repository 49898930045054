import {Component, computed, Input, OnInit, signal} from '@angular/core';
import {Offering} from "../../model/offering";
import {MainHeaderComponent} from "../../components/main-header/main-header.component";
import {MainPanelComponent} from "../../components/main-panel/main-panel.component";
import {MainTitleComponent} from "../../components/main-title/main-title.component";
import {ActivatedRoute, Router, RouterLink} from "@angular/router";
import {AuthService} from "../../services/auth.service";
import {SelectOfferingComponent} from "./components/select-offering/select-offering.component";
import {SelectJobComponent} from "./components/select-job/select-job.component";
import {PureTutorChatComponent} from "./components/pure-tutor-chat/pure-tutor-chat.component";
import {OfferingCategory, OfferingType, Roles, SalesPrepReport, TutorCoachingLevels} from "../../model";
import {TutorService} from "../../services/tutor.service";
import {ProductsService} from "../../services/products.service";
import {MessageService} from "../../services/message.service";
import {PageHeaderComponent} from "../../components/page-header/page-header.component";
import {TutorAvatarComponent} from "../../components/tutor-avatar/tutor-avatar.component";
import {OfferingActionPipe} from "../../pipes/offering-action.pipe";
import {
    JobSelectedEvent
} from "../report-builder/screens/select-job-to-be-done/components/select-job-form/select-job-form.component";
import {ReportService} from "../../services/report.service";
import {SelectReportComponent} from "./components/select-report/select-report.component";
import {SpinnerComponent} from "../../components/spinner/spinner.component";
import {TextToSpeechToggleComponent} from "./components/text-to-speech-toggle/text-to-speech-toggle.component";
import {IVoiceSelection, SpeechSynthesisService} from "../../services/speech-synthesis.service";
import {InfoPanelComponent} from "../../components/info-panel/info-panel.component";
import {TextToSpeechSettingsComponent} from "./components/text-to-speech-settings/text-to-speech-settings.component";

@Component({
    selector: 'app-ai-guide',
    standalone: true,
    imports: [
        MainHeaderComponent,
        MainPanelComponent,
        MainTitleComponent,
        SelectOfferingComponent,
        SelectJobComponent,
        PureTutorChatComponent,
        PageHeaderComponent,
        TutorAvatarComponent,
        RouterLink,
        OfferingActionPipe,
        SelectReportComponent,
        SpinnerComponent,
        TextToSpeechToggleComponent,
        InfoPanelComponent,
        TextToSpeechSettingsComponent
    ],
    templateUrl: './ai-guide.component.html',
    styleUrl: './ai-guide.component.scss'
})
export class AiGuideComponent implements OnInit {
    offering: Offering | undefined;
    job: string | undefined;
    topic = '';

    subject = '';
    userProfile = this.authService.userProfile;
    userInitials = this.authService.userInitials;
    authenticated = this.authService.authenticated;

    selectedVoices = this.speechSynthesisService.selectedVoices;
    // tutorChatMessages = this.tutorService.messages;
    // tutorStarting = this.tutorService.starting;
    // tutorDataLoading = this.tutorService.loading;
    tutorLevel = TutorCoachingLevels.Intermediate;
    showVoiceSettings = false;
    offerings = computed(() => {
        return this.userProfile()!.offerings;
    });

    speechEnabled = false;
    tryMode = false;
    reportMode = false;
    speechNotSupported = false;

    type = signal<OfferingType>(OfferingType.MaterialOrIngredientOrComponent);
    offeringTypes = this.productsService.offeringTypes;
    categories = computed<OfferingCategory[]>(() => {
        return this.offeringTypes().find(x => x.id == this.type())?.categories || [];
    })

    reportsLoading = this.reportService.reportsLoading;
    reports = this.reportService.reports;    
    voices: SpeechSynthesisVoice[] = [];

    TutorCoachingLevels= TutorCoachingLevels;
    
    constructor(private router: Router, private authService: AuthService, public tutorService: TutorService,
        private productsService: ProductsService,
                private speechSynthesisService: SpeechSynthesisService,
                private reportService: ReportService,
                private messageService: MessageService,
        private route: ActivatedRoute) {
        this.reportService.reports()
    }

    ngOnInit() {
        // setTimeout(() => this.showSettings(), 200);
        
        this.checkIfSpeechEnabled();
        
        const snapshot = this.route.snapshot;
        if (snapshot.data['try']) {            
            if (!this.tutorService.started() && !this.tutorService.starting()) {
                this.router.navigate(['/ai-guide/intro']);                
                return;
            }
            this.tryMode = true;
            this.tutorLevel = TutorCoachingLevels.Intermediate;
            this.offering = {
                id: 0,
                categoryId: 0,
                type: this.tutorService.offeringType(),
                name: this.tutorService.offering(),
                jobs: []
            }
            this.job = this.tutorService.job();
        } else {
            this.tutorService.reset();
            this.route.params.subscribe(params => {
                console.log('[AIGuideComponent] params', params, this.tutorService.roles());

                const offeringId = params['offeringId'];
                const job = params['job'];
                const topic = params['topic'];

                this.offering = this.offerings().find(x => x.id == offeringId)

                if (this.offering) {
                    this.job = job;
                    this.topic = topic || '';

                    if (job) {
                        let jobFormatted = job == '_' ? '' : job;
                        if (jobFormatted && this.offering.type == OfferingType.MaterialOrIngredientOrComponent) {
                            jobFormatted = 'produce ' + jobFormatted;
                        }
                        this.subject = `Ask your first question to practice offering <b class="extra-bold">${this.offering.name}</b> to ${jobFormatted}.`;
                        
                        const r = this.tutorService.roles().filter(x => !!x);
                        if (r.length > 0) {                            
                            this.subject += `<br>Customer role` + (r.length > 1 ? 's' : '') + `: ${r.join(', ')}`;
                        }
                    }
                }
            });
        }
    }

    showHome() {
        if (this.tutorService.started()) {
            this.tutorService.reset();
        } else if (this.job) {
            // this.job = '';            
            this.router.navigate(['..'], { relativeTo: this.route });
        } else if (this.offering) {
            // this.offering = undefined;            
            this.router.navigate(['..'], { relativeTo: this.route });
        } else {
            this.router.navigate(['/dashboard']);
        }
        // if (!this.type && this.signUpMode) {
            
        // } else {
        //     this.router.navigate(['..'], { relativeTo: this.route });
        // }
    }

    sendToTutor(message: string) {
        console.log('[ReportViewerComponent] sendToTutor', message, this.tutorLevel);

        const tutor = this.tutorService;
        if (tutor.started()) {
            tutor.send(message).subscribe(() => console.log('[TutorService] send', message));
        } else {
            const o = this.offering;
            if (!o) {
                console.error('offering not set');
            }
            tutor.start(this.tutorLevel, 
                this.offering!.name,
                this.offering!.type,
                this.job,
                this.tutorLevel == TutorCoachingLevels.Beginner ? '' : message);
        }
    }

    selectReport(report: SalesPrepReport) {
        this.offering = this.offerings().find(x => x.id == report.offeringId);
        if (!this.offering) {
            this.messageService.error('We can\'t start your practice session because the corresponding offering has been removed', 'Unable to Start Practice Session');
            return;
        }
        
        this.tutorService.roles.set(report.roles);
        this.router.navigate(['/ai-guide', this.offering!.id, report.job]);
    }
    
    setJob(job: JobSelectedEvent) {
        this.tutorService.roles.set(job.roles);
        this.router.navigate(['/ai-guide', this.offering!.id, job.job]);
    }

    setOffering(o: Offering) {
        this.router.navigate(['/ai-guide', o.id]);
    }

    setCategories(type: OfferingType) {
        this.type.set(type);        
    }

    addOffering(offering: Offering) {
        this.productsService.addOffering(offering)
            .subscribe(offering => {
                console.log('[SelectOffering] addOffering success', offering);
                this.setOffering(offering);
            });
    }

    addJob(job: string) {
        const o = this.offering;
        if (!o) return;

        o.jobs = [...o.jobs, job];

        this.productsService.addOffering(o)
            .subscribe(() => {
                console.log('[SelectOffering] addOffering success');
                this.offering = {...o};
                // this.setJob(job);
            });

    }

    rolePlayAgain() {
        console.log('[AIGuideComponent] rolePlayAgain');
        if (this.authenticated()) {
            this.tutorService.reset();
        } else {
            this.router.navigate(['/ai-guide/intro']);
        }
    }

    back() {
        window.history.back();
    }

    checkIfSpeechEnabled() {
        this.speechEnabled = this.speechSynthesisService.enabled();
    }

    async speechEnabledChanged() {
        this.speechEnabled = !this.speechEnabled;
        if (this.speechEnabled) {
            await this.speechSynthesisService.enable();            
            this.speechEnabled = this.speechSynthesisService.enabled();
            this.speechNotSupported = !this.speechSynthesisService.supported();
        } else {
            this.speechSynthesisService.disable();
            this.speechEnabled = false;
        }
                
    }

    showSettings() {
        this.voices = this.speechSynthesisService.getVoices()
            .filter(x => x.lang.startsWith('en-'));
        
        this.showVoiceSettings = true;
    }

    resetVoices() {
        this.speechSynthesisService.reset();
    }
    
    selectVoice(voice: IVoiceSelection) {
        this.speechSynthesisService.selectVoice(voice);
    }
    
    playingVoice = '';
    
    async playVoice(voice: SpeechSynthesisVoice) {
        if (this.playingVoice == voice.voiceURI) {
            this.speechSynthesisService.stop();            
        } else {
            this.playingVoice = voice.voiceURI;
            await this.speechSynthesisService.speakVoice(
                "Yes, we've been experiencing issues with the drying time; it's taking longer than expected. ", voice.voiceURI);
        }
        
        if (this.playingVoice == voice.voiceURI) {
            this.playingVoice = '';
        }
    }
}
