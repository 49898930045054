<app-page-header [title]="headerTitle" [text]="headerText">
    <div class="logo"><app-company-logo [company]="report.company" /></div>
</app-page-header>


<div class="content sp-wrapped" qa="cont">
    <div class="content-column">
        <h1>Customer Meeting Report: {{ report.callReport?.customerCompany ?? report.company.name }}</h1>
        @if (report.callReport) {    
            <h2 qa="q21">
                {{ userName }}&nbsp;
        <!--        {{ report.callReport?.authorName }}-->
                <em>
                    | <a [routerLink]="['/report', report.id]" class="sp-link blue" title="Click to open Pre Call Report" qa="open-prc">{{ report.callReport.meetingDate | date:'MMMM d, YYYY'}}</a>
                    | {{ report.callReport.meetingType == MeetingType.InPerson ? 'In-person' : 'Virtual' }} Meeting
                </em>
            </h2>
            <div class="actions-row">
                <a (click)="onDownload.emit()" class="sp-link blue">Download PDF</a> 
                | <a title="Revise Answers to Paige" (click)="onRegenerate.emit()" class="sp-link blue">Revise Answers</a> 
                | <a class="sp-link blue disabled">Export to CRM</a>
            </div>
        
            <div class="editable" qa="meet">
                <h2 qa="q22">Meeting Overview <a (click)="onEditMeetingOverview.emit(report.callReport.meetingOverview)"><span class="material-symbols-outlined">edit_note</span></a></h2>
                <p qa="data" [innerHTML]="report.callReport.meetingOverview | formatText"></p>
            </div>
        
            <div class="flex editable editable-title">
                <h2 qa="q23">Desired Customer Outcomes</h2>
                <a title="Add Desired Customer Outcome" (click)="onEditOutcome.emit(-1)"><span class="material-symbols-outlined" qa="ed">add</span></a>
            </div>
            <div class="outcomes" qa="outs">
                @for (x of report.callReport.outcomes; track x.title; let i = $index) {
                    <div class="outcome flex editable" [attr.qa]="'out-' + (i + 1)">
                        <div class="title">{{ i + 1 }}.</div>
                        <div class="flex-column">
                            <div class="title header">{{ x.title }} <a (click)="onEditOutcome.emit(i)"><span class="material-symbols-outlined" qa="ed">edit_note</span></a></div>
                            <div qa="desc">{{ x.description | formatText }}</div>    
                        </div>                
                    </div>
                }
            </div>
        
            <div class="editable" qa="pr-res">
                <h2 qa="q24">Pre-call Research <a (click)="onEditResearch.emit(report.callReport.preCallResearch)"><span class="material-symbols-outlined" qa="ed">edit_note</span></a></h2>
                <p qa="data" [innerHTML]="report.callReport.preCallResearch | formatText"></p>
            </div>
        
            <div class="editable" qa="ot-in">
                <h2 qa="q25">Other Insights <a (click)="onEditInsights.emit(report.callReport.otherInsights)"><span class="material-symbols-outlined" qa="ed">edit_note</span></a></h2>    
                <p qa="data">{{ report.callReport.otherInsights | formatText }}</p>
            </div>
        
            <div class="editable" qa="opp">
                <h2 qa="q26">Opportunities <a (click)="onEditOpportunities.emit(report.callReport.opportunities)"><span class="material-symbols-outlined" qa="ed">edit_note</span></a></h2>
                <p qa="data">{{ report.callReport.opportunities | formatText }}</p>
            </div>
    
            <div class="flex editable editable-title">
                <h2 qa="q27">Next Steps</h2>
                <a title="Add Next Step" (click)="onEditNextStep.emit(-1)"><span class="material-symbols-outlined" qa="ed">add</span></a>
            </div>
            <div class="next-steps" qa="nss">
                @for (step of report.callReport.nextSteps; track step; let i = $index) {
                    <div class="editable next-step" [attr.qa]="'ns-' + (i + 1)">{{ step | formatText }} <a (click)="onEditNextStep.emit(i)"><span class="material-symbols-outlined" qa="ed">edit_note</span></a></div>
                }
            </div>
        }
    </div>
    
    <div class="actions-column">
<!--        <h2>CUSTOMER RECAP</h2>-->
<!--        <a class="sp-button yellow">Generate Email Draft</a>-->
        <h2 qa="cl-opt">CALL REPORT OPTIONS</h2>
<!--        <a class="sp-button">Export to Word</a>-->
        <a (click)="onDownload.emit()" class="sp-button" qa="pdf">Download PDF</a>
        <a title="Revise Answers to Paige" (click)="onRegenerate.emit()" class="sp-button" qa="reg">Revise Answers</a>
        <a title="Open Pre-Call Report" [routerLink]="['/report', report.id]" class="sp-button" qa="open-prc">Pre-Call Report</a>
        <a class="sp-button disabled" qa="crm">Export to CRM</a>
        
    </div>
</div>

