import {computed, Injectable, signal} from '@angular/core';
import {catchError, finalize, of, retry, switchMap, tap} from "rxjs";
import { HttpClient } from "@angular/common/http";
import {ITutorResponse, OfferingType, Roles, TutorChatMessage, TutorCoachingLevels} from "../model";
import {AuthService} from "./auth.service";
import {MessageService} from "./message.service";

@Injectable({
    providedIn: 'root'
})
export class TutorService {

    threadId = signal<string>('');
    messages = signal<TutorChatMessage[]>([]);
    
    advices = signal<IAdvicesGroup | undefined>(undefined);
    strengths = signal<IStrengthsGroup | undefined>(undefined);
    
    started = computed<boolean>(() => !!this.threadId());
    completed = signal<boolean>(false);
    starting = signal<boolean>(false);
    loading = signal<boolean>(false);
    advicesLoading = signal<boolean>(false);
    loadingFailed = signal<boolean>(false);

    numWhat = signal(0);
    numWhy = signal(0);
    numClarify = signal(0);
    numProblem = signal(0);

    offering = signal('');
    offeringType = signal(OfferingType.Unknown);
    job = signal('');
    roles = signal<string[]>([]);

    hadRecentChat = signal(false);
    
    private _level = TutorCoachingLevels.Beginner;

    constructor(private http: HttpClient, private authService: AuthService, private messageService: MessageService) {        
        const lastChat = localStorage.getItem('lastChatDate');        
        if (lastChat) {
            const date = new Date(lastChat);
            const expiration = new Date().getTime() - (60 * 24 * 60 * 60 * 1000) // 30 days
            this.hadRecentChat.set(+date > expiration);
        }
    }
    
    start(level: TutorCoachingLevels, offeringName: string, offeringType: OfferingType, job: string | undefined, message: string) {
        this._level = level;
        
        this.offering.set(offeringName);
        this.offeringType.set(offeringType);
        this.job.set(job ?? '');

        this.hadRecentChat.set(true);
        localStorage.setItem('lastChatDate', new Date().toISOString());

        (<any> window).setThreadId = (id: string) => this.threadId.set(id);
        
        let jobFormatted = job == '_' ? '' : job;
        if (jobFormatted && offeringType == OfferingType.MaterialOrIngredientOrComponent) {
            jobFormatted = 'produce ' + jobFormatted;
        }
        
        let subject =  'My Offering: ' + offeringName;
        
        if (this.authService.authenticated()) {
            subject += '. My Name: ' + this.authService.userName()
                + '. My Company: ' + this.authService.userProfile()?.company;
        }
        
        if (jobFormatted) {
            subject += '. Customer job to be done: ' + jobFormatted            
        }
        
        const roles = this.roles().filter(x => !!x);
        if (roles.length) {
            subject += '.Customer role: ' + roles.join(',');
        } 
        
        this.completed.set(false);
        this.starting.set(true);        
        this.messages.set([]);
        this.threadId.set('');

        if (message) {
            subject += '. salespersonMessage: ' + message;
            this.messages.update(x => [...x, {role: Roles.User, text: message }])
        }
        
        return this.http.post<ITutorResponse>('/api/ai-tutor/start', {
            level,
            message: subject
        }).pipe(
            retry(1),
            tap(x => {
                console.log('[TutorService] start', x);
                this.threadId.set(x.threadId);
            }),
            tap((x) => this._processResponse(x)),
            finalize(() => this.starting.set(false)),
            // switchMap(() => this.send(subject)),
            // filter(message => !!message),
            // switchMap(() => this.send(message)),
        ).subscribe({
            next: x => console.log('[TutorService] start', x),
            error: e => {
                console.error('[TutorService] start', e);
                if (e.status == 400 && !!e.error) {
                    this.messageService.warning(e.error);
                }
            }
        });
    }

    getTips(level: TutorCoachingLevels) {
        console.log('[TutorService] getTips', level);
        
        const threadId = this.threadId();
        this.advicesLoading.set(true);
        this.loadingFailed.set(false);

        this.advices.set(undefined);
        this.strengths.set(undefined);
        
        return this.http.put<FollowUpNotes>('/api/ai-tutor/tips', {
            threadId, level
        }).pipe(
            tap(tips => {
                this.advices.set(tips.advices);
                this.strengths.set(tips.strengths);                
            }),
            finalize(() => this.advicesLoading.set(false)),
            catchError(e => {
                console.error('Unable to load response', e);
                if (e.status == 400 && !!e.error) {
                    this.messageService.warning(e.error);
                } else {
                    this.messageService.error('Unable to load response');
                    }
                this.loadingFailed.set(true);
                return of([]);
            })            
        );
    }
    
    send(message: string) {
        console.log('[TutorService] send', message);

        const threadId = this.threadId();
        this.loading.set(true);
        this.loadingFailed.set(false);
        
        if (message === '**next**') {
            message = 'next';
        } else {
            this.messages.update(x => [...x, {role: Roles.User, text: message }])
        }
        
        //*
        return this.http.put<ITutorResponse>('/api/ai-tutor/say', {
            threadId, message
        }).pipe(
            retry(1),
            tap((event) => this._processResponse(event)),
            finalize(() => this.loading.set(false)),
            catchError(e => {
                if (e.status == 400 && !!e.error) {
                    this.messageService.warning(e.error);
                }
                
                this.loading.set(false);
                this.loadingFailed.set(true);
                return of([]);
            })
        );
        /*/
        
        let messagesCount = this.messages().length;
        return this.http.put<string>('/api/ai-tutor/say', {
            chatId, message
        }, {
            observe: "events",
            responseType: <any>"text",
            reportProgress: true
        }).pipe(
            tap((event) => {
                if ((<any>window).qaLoadingFailure) {
                    if ((<any>window).qaLoadingFailure--) {
                        throw Error('Not implemented');
                    }
                }
                if (event.type == HttpEventType.DownloadProgress) {
                    this._parseMessages(messagesCount, (<HttpDownloadProgressEvent>event).partialText);
                } else if (event.type == HttpEventType.Response) {
                    this._parseMessages(messagesCount, (<any>event).body);
                }
            }),
            finalize(() => this.loading.set(false)),
            catchError(e => {
                console.error('Unable to load response', e);
                this.messageService.error('Unable to load response');
                this.loadingFailed.set(true);
                return of([]);
            })
        );
        */
    }

    reset() {
        this.threadId.set('');
        this.messages.set([]);
        this.completed.set(false)
        
        this.numWhat.set(0);
        this.numWhy.set(0);
        this.numClarify.set(0);
        this.numProblem.set(0);
    }
/*
    private _parseMessages(oldMessagesCount: number, response: string | undefined) {
        if (!response) {
            return;
        }
        
        console.log('[TutorService] _parse', response);
        
        const lines = response.split('\n').map(x => x.trim()).filter(x => !!x);
        const existingCount = this.messages().length - oldMessagesCount;
        for (let i = existingCount; i < lines.length; i++) {
            const line = lines[i];
            const [_role, text, numWhat, numWhy, numClarify] = line.split('|');
            
            if (!text) {
                console.warn('[TutorService] had to skip mailformed message', text);
                continue; // let's skip technical message
            }
            
            if (numWhat && numWhy && numClarify) {                
                this.numWhat.set(+numWhat || 0);
                this.numWhy.set(+numWhy || 0);
                this.numClarify.set(+numClarify || 0);
                this.numProblem.set(+numClarify || 0);
            }

            const role = this._getRole(_role.trim());
            
            if (this._level != TutorCoachingLevels.Beginner && role == Roles.User) continue; // we already added input from the end user to chat

            this.messages.update(x => [...x, {
                role,
                text
            }]);
            
            if (text.toLowerCase().indexOf('goodbye') !== -1) {
                this.getTips(this._level).subscribe(() => {
                    console.log('[TutorService] asked for tips');
                    this.completed.set(true);
                });
            }
        }
        
        console.log('messages: ', this.messages());
    }

    
    private _getRole(_role: string): Roles {
        switch (_role) {
            case 'Coach':
                return Roles.Coach;
            case 'Salesperson':
                return Roles.User;
            case 'Customer':
                return Roles.Customer;
            default: 
                console.error('Unknown role: ' + _role);
                return Roles.Coach;
        }
    }
*/

    /*
    private _parseAdvices(text: string) {
        const lines = text.split('\n');
        lines.push(''); // make sure we have empty line at the end

        const args = lines[0].split('|');
        this.advices.update(x => [...x, {
            role: Roles.Coach,
            text: args[1]
        }]);

        let message = '';
        for (let i = 2; i < lines.length; i++) {
            if (!lines[i]) {
                if (message) {
                    this.advices.update(x => [...x, {
                        role: Roles.Coach,
                        text: message
                    }]);
                }
                message = '';
            } else {
                message += lines[i] + '\n';
            }
        }

        console.log('messages: ', this.advices());
    }
    */
    private _processResponse(event: ITutorResponse) {
        if ((<any>window).qaLoadingFailure) {
            if ((<any>window).qaLoadingFailure--) {
                throw Error('Not implemented');
            }
        }

        const message = event.message;
        if (this._level == TutorCoachingLevels.Beginner && message) {

            const existing = this.messages().find(x => x.role == Roles.User && x.text == message);
            if (!existing) // workaround for duplicate messages
                this.messages.update(x => [...x, {
                    role: Roles.User,
                    text: message
                }]);
        }

        if (event.customerResponse) {
            this.messages.update(x => [...x, {
                role: Roles.Customer,
                text: event.customerResponse
            }]);
        }

        if (event.coachResponse) {            
            this.messages.update(x => [...x, {
                role: Roles.Coach,
                text: event.coachResponse
            }]);
        }

        this.numWhat.set(+event.numWHAT || 0);
        this.numWhy.set(+event.numWHY || 0);
        this.numClarify.set(+event.numCLARIFY || 0);
        this.numProblem.set(+event.numPROBLEM || 0);

        if (message.toLowerCase().indexOf('goodbye') !== -1
            || event.customerResponse?.toLowerCase().indexOf('goodbye') !== -1) {

            this.completed.set(true);

            this.getTips(this._level).subscribe(() => {
                console.log('[TutorService] asked for tips');
            });
        }

        console.log('messages: ', this.messages());
    }
}

interface StartChatResponse {
    chatId: string;
}

export interface IFollowUpItem {
    title: string;
    text: string;
}

export interface IAdvicesGroup {
    summary: string;
    advices: IFollowUpItem[];
}

export interface IStrengthsGroup {
    summary: string;
    strengths: IFollowUpItem[];
}

export interface FollowUpNotes {    
    advices: IAdvicesGroup;
    strengths: IStrengthsGroup;
} 