<div class="header">
    <div class="text" [innerHTML]="formattedText"></div>

    <app-speaking-animation [class.speaking]="speaking" />
</div>

<div class="input-wrapper">
    @if (options?.length) {
        <div class="options">
            @for (o of options; track o) {
                <div (click)="onSelect.emit(o)" class="option" [class.selected]="o.selected" [innerHTML]="o.title"></div>
            }
        </div>
    } @else if (options?.length !== 0) {
        <div class="input">
            <div #editableDiv class="textarea" contenteditable="true" (change)="textChange()">{{ input }}</div>
        </div>
    }


    @if (options?.length !== 0) {
        <div class="listening-wrapper" [class.options-input]="options?.length">
            <button type="button" (click)="onToggleListening.emit()" [class.listening]="listening" title="Click to speak, or simply press the spacebar">
                <app-recording-animation />
                <span class="material-symbols-outlined mic">mic</span>
            </button>
        </div>
    }
</div>

@if (options?.length) {
    <app-form-navigation-buttons (onPrev)="onPrev.emit()" (onNext)="next()" />
} @else {
    <app-form-navigation-buttons (onPrev)="onPrev.emit()" (onNext)="next()" [nextTitle]="nextTitle" />
}