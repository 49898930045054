import {Directive, HostListener} from '@angular/core';

@Directive({
    selector: '[appPreventDefaults]',
    standalone: true
})
export class PreventDefaultsDirective {

    @HostListener('click', ['$event'])
    onClick(event: Event) {
        event.preventDefault();
        event.stopPropagation();
    }

}
