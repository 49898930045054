<div class="header" qa="sel2-off">
    <strong>
        {{ step }}. Select an offering
        @if (aiGuideMode) { 
            <a class="sp-link" (click)="onSelectReport.emit()" qa="rpl">or a report</a>
        }
    </strong> for {{ title }}
</div>

@for (o of offerings; track o.name; let index = $index) {
    <a class="sp-selectable mobile-layout" (click)="onSelect.emit(o)" qa="name">
        {{ o.name }}
        <i class="material-icons arrow" qa="c-arr">
            arrow_forward_ios
        </i>
    </a>

    <div class="sp-selectable desktop-layout" [class.selected]="index == selectedIndex" [attr.qa]="'offer-' + (index + 1)">
        <input type="radio" id="offering-{{index}}" value="{{index}}" [(ngModel)]="selectedIndex" qa="inpt-off">
        <label for="offering-{{index}}" qa="for-lbl">{{ o.name }}</label>
    </div>

} @empty {
    <h1 style="margin: 1.5em 0 2em 0"> There are no offerings yet.</h1>
}

<button (click)="onAddNew.emit()" type="button" class="alternative">
    <i class="material-icons">add</i>
    <span class="text" qa="add-off">Add another offering</span>
</button>

<app-step-buttons class="desktop-layout"
                  (onBack)="onBack.emit()"
                  (onNext)="onSelect.emit(offerings[selectedIndex])"
                  [backVisible]="backVisible"
                  [nextEnabled]="selectedIndex !== -1" />
