import {Component, OnDestroy} from '@angular/core';
import {toObservable} from "@angular/core/rxjs-interop";
import {MainHeaderComponent} from "../../components/main-header/main-header.component";
import {FooterSmartComponent} from "../../components/footer-smart/footer-smart.component";
import {ReportService} from "../../services/report.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Title} from "@angular/platform-browser";
import {Subscription} from "rxjs";
import {PureCallReportViewerComponent} from "./components/pure-call-report-viewer/pure-call-report-viewer.component";
import {SpinnerComponent} from "../../components/spinner/spinner.component";
import {DatePipe} from "@angular/common";
import {AuthService} from "../../services/auth.service";
import {AutofocusDirective} from "../../directives/autofocus.directive";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {InfoPanelComponent} from "../../components/info-panel/info-panel.component";
import {AutosizeDirective} from "../../directives/autosize.directive";
import {CallReport, CallReportGeneratedOutcome} from "../../model";
import {MessageService} from "../../services/message.service";
import {CallReportBuilderService} from "../../services/call-report-builder.service";
import {PdfExportService} from "../../services/pdfExport.service";

@Component({
    selector: 'app-call-report-viewer',
    standalone: true,
    imports: [
        MainHeaderComponent,
        FooterSmartComponent,
        PureCallReportViewerComponent,
        SpinnerComponent,
        DatePipe,
        AutofocusDirective,
        FormsModule,
        InfoPanelComponent,
        ReactiveFormsModule,
        AutosizeDirective
    ],
    host: {ngSkipHydration: 'true'},
    templateUrl: './call-report-viewer.component.html',
    styleUrl: './call-report-viewer.component.scss'
})
export class CallReportViewerComponent implements OnDestroy {
    subs: Subscription[] = [];

    report = this.reportService.reportData;
    userName = this.authService.userName;

    editMeetingOverview = false;
    editResearch = false;
    editInsights = false;
    editOpportunities = false;
    editNextStepIndex = 0;
    editOutcomeIndex = 0;
    editOutcome : CallReportGeneratedOutcome | undefined;
    
    formTitle = '';
    formText = '';
    editing = false;
    saving = false;
    
    constructor(private reportService: ReportService,
                private authService: AuthService,
                private pdfExportService: PdfExportService,
                private messageService: MessageService,
                private callReportBuilderService: CallReportBuilderService,
                private router: Router,
                route: ActivatedRoute, title: Title) {
        const id = route.snapshot.params['id'];
        if (!id) {
            throw Error('ID is undefined');
        }
        reportService.selectedReportId.set(id);
        const sub = toObservable(reportService.reportData).subscribe(s => title.setTitle(
            `${s?.company.name} - ${s?.subject}`
        ));
        this.subs.push(sub);
    }

    ngOnDestroy() {
        this.subs.forEach(s => s.unsubscribe());
    }
    
    edit(text: string) {
        this.formText = text;
        this.editing = true;
        this.formTitle = this._getFormTitle();
    }

    delete() {        
        this.messageService.confirm('Are you sure you want to proceed?', () => this._delete());
    }
    
    save(valid: boolean) {
        console.log('[CallReportViewerComponent] save');

        const text = this.formText;
        const r = this.report()!;
        const d = r.callReport!;
        if (valid) {            
            if (this.editOutcome) {
                if (this.editOutcomeIndex === -1) {
                    d.outcomes.push({...this.editOutcome});
                } else {
                    d.outcomes[this.editOutcomeIndex - 1] = {...this.editOutcome};
                }
            } else if (this.editNextStepIndex) {
                if (this.editNextStepIndex === -1) {
                    d.nextSteps.push(text);                    
                } else {
                    d.nextSteps[this.editNextStepIndex - 1] = text;
                }
            } else {
                if (this.editMeetingOverview) d.meetingOverview = text;
                if (this.editResearch) d.preCallResearch = text;
                if (this.editInsights) d.otherInsights = text;
                if (this.editOpportunities) d.opportunities = text;                
            }
            
            this._save(r.id, d);            
        }
    }
    
    private _save(id: string, d: CallReport) {
        this.saving = true;
        this.reportService.updateCallReport(id, d).subscribe(
            () => {
                console.log('[CallReportViewerComponent] save successfully');
                this.saving = false;
                this.stopEdit();
                this.reportService.updateReport();
            });
    }
    
    stopEdit() {
        this.editing = false;
        this.editMeetingOverview = false;
        this.editResearch = false;
        this.editInsights = false;
        this.editOpportunities = false;
        this.editNextStepIndex = 0;
        this.editOutcomeIndex = 0;
        this.editOutcome = undefined;
    }

    private _getFormTitle() {
        if (this.editMeetingOverview) return 'Meeting Overview';
        if (this.editResearch) return 'Pre-call Research';
        if (this.editInsights) return 'Other Insights';
        if (this.editOpportunities) return 'Opportunities';
        if (this.editNextStepIndex) return 'Next Steps';
        
        return 'NA';
    }

    _editNextStep(index: number) {
        if (index === -1) {
            this.editNextStepIndex = -1;
            this.edit('');
        } else {
            this.editNextStepIndex = index + 1;
            this.edit(this.report()!.callReport!.nextSteps[index]);
        }
    }
    
    _editOutcome(index: number) {
        if (index === -1) {
            this.editOutcomeIndex = -1;
            this.editOutcome = {title: '', description: ''};
        } else {
            const o = this.report()!.callReport!.outcomes[index];
            this.editOutcomeIndex = index + 1;
            this.editOutcome = {...o};
        }
        
        this.editing = true;
        this.formTitle = 'Desired Customer Outcome';
    }

    private _delete() {
        console.log('[CallReportViewerComponent] delete');
        const r = this.report()!;
        const d = r.callReport!;
        
        if (this.editOutcome) {
            d.outcomes.splice(this.editOutcomeIndex - 1, 1);
        } else if (this.editNextStepIndex) {
            d.nextSteps.splice(this.editNextStepIndex - 1, 1);
        }

        this._save(r.id, d);
    }

    regenerate() {
        const id = this.report()?.id;
        if (id) {
            this.callReportBuilderService.loadInputData(id)
                .subscribe(() => this.router.navigate(['/call-report-builder', id]));
        }
    }

    async downloadPdf() {
        console.log('[CallReportViewerComponent] downloadPdf');
        const d = this.report();
        if (d) {
            await this.pdfExportService.exportCallReport(d);
        }
    }
}
