<div class="role-{{message.role}}" (click)="onClick($event, message.text)" qa="roles" >
    @if (message.role == 'customer') {
        <app-user-avatar class="customer" [initials]="'C'"/>
    } @else if (message.role == 'user') {
        <app-user-avatar class="user" [initials]="userInitials"/>
    } @else if (message.role == 'coach') {
        <app-tutor-avatar class="coach" />
    }

    @if (sentences?.length) {
        <span class="text" [class.speaking]="message.speaking" qa="txt">
            @for (s of sentences; track i; let i = $index) {
                <span [class.active]="speakingSentenceIndex == i">{{ s }}&nbsp;</span> 
            }
        </span>
    } @else {
        <span [class.speaking]="message.speaking" [innerHTML]="message.text" qa="txt"></span>
    }

    <!--@if (message.role == 'coach' && message.questionType) {
        <p qa="p-quest"><br>
            Question Type: {{message.questionType}}<br>
            Question Quality: {{message.questionQuality}}
        </p>            
    }-->
</div>