import {TextSection} from "../text-section";
import {PdfGenerator} from "../pdf-generator";
import {NewsArticle, Stakeholder} from "../../../model";

export class StakeholderSection {
    private maxWidth = 340.910;

    private briefTitle = 'JOB DESCRIPTION';
    private titleSection: TextSection;
    private summarySection: TextSection;
    private _briefLinkSection: TextSection;

    constructor(private pdf: PdfGenerator, private a: Stakeholder, index: number) {
        const title = index + '. ' + a.title.replace(/<\/?[^>]+(>|$)/g, "");
        const summary = a.description.replace(/<\/?[^>]+(>|$)/g, "");

        this.titleSection = pdf.createSection(title, this.maxWidth, 'Raleway-Bold', 12)
        this.summarySection = pdf.createSection(summary, this.maxWidth, 'Raleway-Regular', 9)
        this._briefLinkSection = pdf.createSection(this.briefTitle, this.maxWidth, 'Raleway-Regular', 9)
    }

    draw(y: number) {
        const pdf = this.pdf;
        const doc = pdf.doc;
        const a = this.a;

        let x = pdf.styles.paddingX;
        
        y = this.titleSection.draw(pdf, x, y);
        // y += 18.283;
        y = this.summarySection.draw(pdf, x, y);

        const { title, description } = this.a;
        const { id, offeringName, offeringType, job } = pdf.report;

        const url = `${location.origin}/brief/job/?` +
            new URLSearchParams({ id, title, description, offeringName, offeringType: '' + offeringType, job });
        
        doc.setTextColor(pdf.styles.linkColor);
        doc.textWithLink(this.briefTitle, x, y, { baseline: 'top', url: url.toString() });
        doc.setTextColor(pdf.styles.textColor);
        
        return y + pdf.doc.getLineHeight();
    }

    getHeight() {
        return this.pdf.getLineHeight('Raleway-MediumItalic', 9) * 3
            + this.titleSection.getHeight(this.pdf)
            + this.summarySection.getHeight(this.pdf)
            + this._briefLinkSection.getHeight(this.pdf);
    }
}