import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import {TutorChatMessage, TutorCoachingLevels} from "../../../../model";
import {FormsModule} from "@angular/forms";
import {StartChatComponent} from "../start-chat/start-chat.component";
import {MessageFormComponent} from "../message-form/message-form.component";
import {ChatMessagesComponent} from "../chat-messages/chat-messages.component";
import {ChatHeaderComponent} from "../chat-header/chat-header.component";
import {NoviceActionsComponent} from "../novice-actions/novice-actions.component";
import {OfferingType} from "../../../../model";
import {NextStepsComponent} from "../next-steps/next-steps.component";
import {RecommendedElearningComponent} from "../recommended-elearning/recommended-elearning.component";
import {StrengthsComponent} from "../strengths/strengths.component";
import {InterviewSummaryComponent} from "../interview-summary/interview-summary.component";
import {IAdvicesGroup, IStrengthsGroup} from "../../../../services/tutor.service";
import {AdvicesComponent} from "../advices/advices.component";
import {SpinnerComponent} from "../../../../components/spinner/spinner.component";

@Component({
    selector: 'app-pure-tutor-chat',
    standalone: true,
    imports: [
        FormsModule,
        StartChatComponent,
        MessageFormComponent,
        ChatMessagesComponent,
        ChatHeaderComponent,
        NoviceActionsComponent,
        NextStepsComponent,
        RecommendedElearningComponent,
        StrengthsComponent,
        InterviewSummaryComponent,
        AdvicesComponent,
        SpinnerComponent
    ],
    templateUrl: './pure-tutor-chat.component.html',
    styleUrl: './pure-tutor-chat.component.scss'
})
export class PureTutorChatComponent implements OnChanges {
    
    @Input() authenticated = true;
    @Input() userInitials = '';
    
    @Input() messages: TutorChatMessage[] = [];
    
    @Input() advices: IAdvicesGroup | undefined;
    @Input() strengths: IStrengthsGroup | undefined;
    
    @Input() offeringName = '';
    @Input() offeringType = OfferingType.Unknown;
    @Input() job = '';
    @Input() topic = '';
    
    @Input() what = 0;
    @Input() why = 0;
    @Input() clarify = 0;
    @Input() problem = 0;
    @Input() started = false;
    @Input() starting = false;
    @Input() loading = false;
    @Input() advicesLoading = false;
    @Input() completed = false;
    @Input() inlineMode = false;
    @Input() nextSteps = false;
    @Input() speechEnabled = false;

    @Input() get coachingLevel() {
        return this._coachingLevel;
    }
    
    set coachingLevel(level: TutorCoachingLevels) {
        if (this._coachingLevel != level) {
            this._coachingLevel = level
            this.coachingLevelChange.emit(this._coachingLevel);
        }
    }

    @Output() onStart = new EventEmitter();
    @Output() onSend = new EventEmitter<string>();
    @Output() onClose = new EventEmitter();
    @Output() coachingLevelChange = new EventEmitter<TutorCoachingLevels>();

    @Output() onRolePlayAgain = new EventEmitter();

    @ViewChild('chat', { static: false }) chatDiv: ElementRef | undefined; 

    TutorCoachingLevels = TutorCoachingLevels;

    _coachingLevel = TutorCoachingLevels.Beginner;

    
    ngOnChanges(changes: SimpleChanges) {
        if (!this.started) {
            this.nextSteps = false;
        }
    }

    scrollToBottom() {
        setTimeout(() => {
            const divRef = this.chatDiv;
            
            if (!divRef) return;
            
            const div = divRef.nativeElement;
            if (!div) return;
            div.scrollTo({
                top: div.scrollHeight,
                behavior: 'smooth',
            });            
        }, 0);
    }

    showNextSteps() {
        console.log('[PureTutorChatComponent] showNextSteps');
        this.nextSteps = true;
        this.scrollToBottom();
    }

    selectMessage(message: string) {
        console.log('[PureTutorChatComponent] selectMessage', message);
        
        const i = message.indexOf('"');
        
        if (i === -1) {
            this.onSend.emit(message);
        } else {
            this.onSend.emit(message.substring(i + 1, message.lastIndexOf('"')));
        }
    }
}
